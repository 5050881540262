import React, { useEffect, useState } from 'react'

import randomApi from '../../../axiosConfig/randomApi'
import { getFormattedDate } from '../../../utils/dates'
import { modeOffOn, iconModalError, iconCircle } from '../../../constants/icons'
import { STATUS_COLOR, CLOSE_TICKET_MESSAGE } from '../../../constants/tickets'
import { CLOSE_TICKET, REOPEN_TICKET } from '../../../constants/urls'
import ActionItem from '../../shared/ActionsPopUp/ActionItem'
import ActionsPopUp from '../../shared/ActionsPopUp/ActionsPopUp'
import Button from '../../shared/Button'
import RawLoader from '../../shared/Loader/RawLoader'
import Modal from '../../shared/Modal/Modal'

const Header = ({ ticket, isClosedTicket }) => {
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [successfulRequest, setSuccessfulRequest] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setModalText] = useState('')

  const handleOpenModal = async () => {
    setLoading(true)
    try {
      const body = { closingMessage: CLOSE_TICKET_MESSAGE }
      await randomApi().put(CLOSE_TICKET(ticket.id), body)
      setError(false)
      setSuccessfulRequest(true)
      setModalText('Solicitud cerrada con éxito')
    } catch {
      setError(true)
      setModalText('Ocurrió un error')
    }
    setLoading(false)
    setOpenResponseModal(true)
    setIsModalOpen(true)
  }

  const handleReopenModal = async () => {
    setLoading(true)
    try {
      await randomApi().post(REOPEN_TICKET(ticket.id))
      setError(false)
      setSuccessfulRequest(true)
      setModalText('Solicitud re-abierta con éxito')
    } catch {
      setError(true)
      setModalText('Ocurrió un error')
    }
    setLoading(false)
    setOpenResponseModal(true)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setOpenResponseModal(false)
  }

  useEffect(() => {
    let timer
    if (successfulRequest) {
      timer = setTimeout(() => {
        window.location.reload()
      }, 10000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [successfulRequest])

  useEffect(() => {
    if (isModalOpen && successfulRequest && !openResponseModal) {
      window.location.reload()
    }
  }, [isModalOpen, successfulRequest, openResponseModal])

  return (
    <div className="flex flex-wrap justify-between bg-white rounded-lg py-4 px-6 flex-row">
      <div className='w-1/5 items-center'>
        <div
          className={`mb-1 py-1 px-4 text-sm font-medium 
          ${STATUS_COLOR[ticket.status]} w-fit rounded`}
        >
          {ticket.status}
        </div>
        <div className="font-medium">Solicitud #{ticket.id}</div>
      </div>
      <div className="flex gap-8 text-sm w-3/5 items-center justify-center">
        {ticket.package && Object.keys(ticket.package).length > 0 && (
          <>
            <div>
              <div className="font-medium">O. de venta</div>
              <div className="text-medium-dark-grey">{ticket.package.orderId}</div>
            </div>
            <div>
              <div className="font-medium">Nombre</div>
              <div className="text-medium-dark-grey">{ticket.package.customerName}</div>
            </div>
            <div>
              <div className="font-medium">Email</div>
              <div className="text-medium-dark-grey">{ticket.package.customerEmail}</div>
            </div>
          </>
        )}
        <div>
          <div className="font-medium">Fecha de creación</div>
          <div className="text-medium-dark-grey">{getFormattedDate(ticket.createdAt)}</div>
        </div>
      </div>
      <div className="flex items-center w-1/5 justify-end">
        <div className="relative">
          <ActionsPopUp right={false}>
            <div className="absolute top-full left-0 mt-1 z-30 bg-white rounded-lg shadow-xl text-xs py-2 px-3 w-max">
              <ActionItem
                icon={modeOffOn}
                action={() => isClosedTicket ? handleReopenModal() : handleOpenModal()}
                text={isClosedTicket ? 'Re-abrir solicitud' : 'Cerrar solicitud'}
              />
            </div>
          </ActionsPopUp>
        </div>
      </div>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-900 z-50">
          <RawLoader />
        </div>
      )}
      <Modal show={openResponseModal} handleClose={handleModalClose}>
        <div className="flex flex-col justify-center items-center my-4 max-w-md text-center space-y-4">
          <img
            alt="icon"
            src={error ? iconModalError : iconCircle}
            className="mx-auto w-14 h-auto pb-5"
          />
          {modalText}
        </div>
        <div className="flex justify-center gap-2 mt-5">
          <Button onClick={handleModalClose} color="bg-normal-pinflag w-full" border="border border-normal-pinflag">
            {error ? 'Continuar' : 'Listo'}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default Header
