import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import randomApi from '../../axiosConfig/randomApi'
import NoAccess from '../../components/NoAccess'
import Products from '../../components/Sales/SalesDetails/Products'
import FileViewer from '../../components/shared/FileViewer'
import HeaderTitle from '../../components/shared/HeaderTitle'
import RawLoader from '../../components/shared/Loader/RawLoader'
import LoadingError from '../../components/shared/LoadingError'
import Modal from '../../components/shared/Modal/Modal'
import Button from '../../components/shared/Button'
import Chat from '../../components/Tickets/ShowTicket/Chat'
import Header from '../../components/Tickets/ShowTicket/Header'
import TimeLine from '../../components/Tickets/ShowTicket/Timeline'
import UploadFile from '../../components/Tickets/ShowTicket/UploadFile'
import { SALES_DETAIL_ICONS, addCircle, photoLibrary, photo, contentCopy, chevron } from '../../constants/icons'
import ROUTES from '../../constants/routes'
import { DELIVERY_TITLES } from '../../constants/shippings'
import { CLOSED_TICKET_STATUSES } from '../../constants/tickets'
import { TICKET_URL } from '../../constants/urls'
import { notificationContext } from '../../contexts/NotificationContext'
import { AuthContext } from '../../contexts/Store'
import { TicketsContext } from '../../contexts/TicketsContext'
import { getSale } from '../../helpers/request/sales'
import { readNotificationHelper, countNewMessagesByTicketId } from '../../helpers/notifications'
import useNotifications from '../../hooks/useNotifications'

const ShowTicket = () => {
  const { ticketId } = useParams()
  const [ticket, setTicket] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openFiles, setOpenFiles] = useState(false)
  const [openUploader, setOpenUploader] = useState(false)
  const [filesResponse, setFilesResponse] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState([])
  const [filteredFiles, setFilteredFiles] = useState([])
  const [associatedPackage, setAssociatedPackage] = useState(null)
  const [unreadMessages, setUnreadMessages] = useState(0)
  const [chatMessages, setChatMessages] = useState([])
  const [isClosedTicket, setIsClosedTicket] = useState(false)

  const navigate = useNavigate()

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const { ticketsMessages, setTicketsMessages, ticketsInfo } = useContext(notificationContext)

  const {
    previousTicket,
    nextTicket,
    setActualTicket
  } = useContext(TicketsContext)

  const { readNotificationsAndUpdate } = useNotifications()

  useEffect(() => {
    if (filesResponse) {
      setSelectedFilter(filesResponse.filter(item => item.uploadedBy === 'ecommerce').length === 0 ? 'pinflag' : 'ecommerce')
      setFilteredFiles(
        filesResponse.filter(item => item.uploadedBy === 'ecommerce').length === 0 ?
        filesResponse.filter(item => item.uploadedBy === 'pinflag') :
        filesResponse.filter(item => item.uploadedBy === 'ecommerce')
      )
    }
  }, [filesResponse])

  const setTicketResponseValues = (response) => {
    setTicket(response.data)
    setChatMessages(response.data.TicketChats)
    setFilesResponse(response.data.TicketFiles)
    setActualTicket(response.data.id)
    setIsClosedTicket(CLOSED_TICKET_STATUSES.includes(response.data.status))
  }

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await randomApi().get(TICKET_URL(ticketId))
        setTicketResponseValues(response)
        await readNotificationHelper(ticketsInfo, ticketId, readNotificationsAndUpdate)
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    fetchTicket()
    return () => {
      setTicket(null)
      setLoading(true)
      setError(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActualTicket, ticketId])

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const response = await getSale(ticket.package.packageId, {params: {
          origin: 'admin',
          showNotActive: true
        }})
        setAssociatedPackage(response)
      } catch {
        console.log('no package')
      } finally {
        setLoading(false)
      }
    }
    if (ticket && ticket.package && ticket.package.packageId) fetchPackage()
    return () => {
      setAssociatedPackage(null)
      setLoading(true)
      setError(false)
    }
  }, [ticket])

  useEffect(() => {
    if (ticketsMessages[ticketId] !== undefined && ticketsMessages[ticketId] !== null) {
      setUnreadMessages(ticketsMessages[ticketId])
    } else {
      setUnreadMessages(0)
    }
  }, [ticketId, ticketsMessages, ticket])

  useEffect(() => {
    if (ticketsInfo?.notifications) {
      setTicketsMessages(prevMessages =>
        countNewMessagesByTicketId(ticketsInfo.notifications, prevMessages, ticketId, false)
      )
    }
  }, [ticketsInfo?.notifications, setTicketsMessages, ticketId])

  if (!hasAccess('tickets')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  const handlePreviousButton = async () => {
    setTicketsMessages((prev) => ({
      ...prev,
      [ticketId]: 0
    }))
    await readNotificationHelper(ticketsInfo, ticketId, readNotificationsAndUpdate)
    navigate(`${ROUTES.TICKETS}/${previousTicket}`)
  }

  const handleNextButton = async () => {
    setTicketsMessages((prev) => ({
      ...prev,
      [ticketId]: 0
    }))
    await readNotificationHelper(ticketsInfo, ticketId, readNotificationsAndUpdate)
    navigate(`${ROUTES.TICKETS}/${nextTicket}`)
  }

  return (
    <div className="w-full min-h-screen bg-light-grey flex flex-col">
      <div className='w-full justify-between flex flex-row pt-10 items-center'>
        <HeaderTitle
          title="Solicitudes"
          subtitle="Ver solicitud"
          goBack={() => navigate(ROUTES.TICKETS)}
        />
        <div className="flex flex-wrap pt-8 pb-4 pl-10 pr-8 gap-2">
          <Button
            onClick={handlePreviousButton}
            color="bg-transparent"
            border="border border-gray-300"
            inactive={!previousTicket}
            small
            textColor=""
          >
            <div className="px-1 flex items-center py-2">
              <img className="w-2.5 h-auto" src={chevron} alt="Anterior" />
            </div>
          </Button>
          <Button
            onClick={handleNextButton}
            color="bg-transparent"
            border="border border-gray-300"
            inactive={!nextTicket}
            small
            textColor=""
          >
            <div className="px-1 flex items-center  py-2">
              <img className="w-2.5 h-auto rotate-180" src={chevron} alt="Siguiente"/>
            </div>
          </Button>
        </div>
      </div>
      <div className="m-8">
        {(loading || loadingResources) && (
          <div className="flex justify-center items-center">
            <RawLoader />
          </div>
        )}
        {error && <LoadingError />}
        {!loading && !error && ticket && (
          <>
            <Header ticket={ticket} isClosedTicket={isClosedTicket} />
            <div className="flex flex-col mt-4 gap-4 lg:grid lg:grid-cols-3">
              <div className="bg-white rounded-lg py-2">
                <div className="ml-8 mt-4 font-medium">Estado</div>
                <div className="h-[calc(100%-3rem)] overflow-auto">
                  {ticket?.TicketStatusLogs && ticket.TicketStatusLogs.length > 0 && (
                    <TimeLine statusLogs={ticket.TicketStatusLogs} />
                  )}
                </div>
              </div>
              <div className="text-sm flex flex-col gap-4">
                <div className="bg-white rounded-lg p-8">
                  <div className="text-base font-medium">Detalle</div>
                  <div className="mt-4">
                    <div className="font-medium">Motivo</div>
                    <div className="mt-2 text-medium-dark-grey">{ticket.type}</div>
                  </div>
                  <div className="mt-4">
                    <div className="font-medium">Detalle de la solicitud</div>
                    <div className="mt-2 text-medium-dark-grey whitespace-pre-wrap">{ticket.description}</div>
                  </div>
                </div>
                <div className='bg-white rounded-lg p-2 flex flex-col'>
                  <div className='flex justify-between items-center px-3'>
                    <div className='flex flex-row gap-x-3 items-center'>
                      <img src={photoLibrary} alt="" className="w-7 my-auto" />
                      <div className="font-medium">Archivos adjuntos</div>
                    </div>
                    <Button
                    color="cursor-pointer bg-transparent"
                    textColor="text-normal-pinflag"
                    onClick={() => setOpenUploader(true)}>
                      <div className='flex flex-row gap-x-3'>
                        <div className="flex items-center justify-center">
                        <img src={addCircle} alt="" className="w-5 my-auto" />
                        </div>
                        <div className="font-medium">Agregar</div>
                      </div>
                    </Button>
                  </div>
                  {filesResponse.length > 0 && (
                    <div className='bg-light-grey rounded-lg p-3 flex flex-row items-center mt-4'>
                      <img src={photo} alt="archivos" className="w-7 my-auto" />
                      <div className="text-medium-dark-grey pl-4">
                        {filesResponse.length} {filesResponse.length === 1 ? 'archivo' : 'archivos'}
                      </div>
                      <Button
                        color="underline cursor-pointer bg-transparent"
                        textColor="text-normal-pinflag"
                        onClick={() => setOpenFiles(true)}
                      >
                        Ver
                      </Button>
                    </div>
                  )}
                </div>
                {ticket?.package && Object.keys(ticket.package).length > 0 && (
                  <div className="bg-white rounded-lg p-8">
                    <div className="text-base font-medium">Información de envío</div>
                    <div className="grid grid-cols-3 gap-3">
                      <div className="mt-4">
                        <div className="font-medium">Courier</div>
                        <div className="mt-2 text-medium-dark-grey">{ticket.package.courier}</div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium mb-2">O. de transporte</div>
                        {associatedPackage && associatedPackage !== null && (
                          associatedPackage.courier?.trackingUrl &&
                          associatedPackage.trackingNumber ? (
                            <button
                              type="button"
                              onClick={() =>
                                window.open(
                                  `${associatedPackage.courier?.trackingUrl}${
                                    ['globaltracking', 'recibelo', 'alasxpress'].includes(associatedPackage.courier.name)
                                      ? ''
                                      : associatedPackage.trackingNumber
                                  }`
                                )
                              }
                              className="inline-flex text-normal-pinflag underline"
                            >
                              {associatedPackage.trackingNumber}
                              <img src={contentCopy} alt="link a tracking" className="ml-1 w-4 pt-0.5" />
                            </button>
                          ) : (
                            associatedPackage.trackingNumber || '-'
                          )
                        )}
                      </div>
                      <div className="mt-4 row-span-2">
                        <div className="font-medium">Dirección</div>
                        <div className="mt-2 text-medium-dark-grey">{ticket.package.address}</div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium">Tipo de envío</div>
                        <div className="mt-2 text-medium-dark-grey">
                          {DELIVERY_TITLES[ticket.package.deliveryType]}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium">Tipo de servicio</div>
                        <div className="mt-2 text-medium-dark-grey">
                          {ticket.package.courierService}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='flex flex-col gap-4'>
                <div className="bg-white h-[60vh] p-8 flex flex-col gap-4 rounded-lg">
                  <div className='flex flex-row gap-x-3'>
                    <div className="font-medium">Chat</div>
                    {unreadMessages > 0 && (
                      <div className='bg-red rounded-full text-white px-2'>
                      {unreadMessages} {unreadMessages === 1 ? 'mensaje nuevo' : 'mensajes nuevos'}
                      </div>
                    )}
                  </div>
                  <div className="flex-1 overflow-hidden">
                    <Chat
                      chatHistory={chatMessages}
                      ticketId={ticket.id}
                      closingMessage={ticket.closingMessage}
                      unreadMessages={unreadMessages}
                      isClosedTicket={isClosedTicket}
                    />
                  </div>
                </div>
                {associatedPackage &&
                Object.keys(associatedPackage.products).length > 0 && (
                  <div  className='bg-white rounded-lg'>
                    <div className="p-6">
                      <div className="flex gap-2 mb-2 font-medium">
                        <img src={SALES_DETAIL_ICONS.purchase} alt="" className="w-4 my-auto" />
                        Compra
                      </div>
                      <Products
                        products={associatedPackage.products}
                        shippingPaid={associatedPackage.shippingPaid ?
                          associatedPackage.shippingPaid : 0}
                        totalProductsPrice={associatedPackage.totalProductsPrice}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {ticket && ticket.id && (
        <Modal title="Subir archivo" show={openUploader} handleClose={() => setOpenUploader(false)}>
          <UploadFile ticketId={ticket.id} setter={setFilesResponse} />
        </Modal>
      )}
      {filesResponse && filesResponse.length > 0 && filteredFiles.length > 0 && (
        <Modal title="Archivos adjuntos" show={openFiles} handleClose={() => setOpenFiles(false)}>
          <FileViewer
            files={filesResponse}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filteredFiles={filteredFiles}
            setFilteredFiles={setFilteredFiles}
            urlGetter={(object) => object?.fileUrl}
            filters={['ecommerce', 'pinflag']}
          />
        </Modal>
      )}
    </div>
  )
}

export default ShowTicket
