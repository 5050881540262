import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '../../../shared/Table/Table'
import Pagination from '../../../shared/Pagination/Pagination'
import { sortArrayByKey } from '../../../../utils/arrays'
import ROUTES from '../../../../constants/routes'
import {
  SALE_CUSTOMER,
  SALE_CUSTOMER_HEADER,
  SALE_DATE,
  SALE_DATE_HEADER,
  SALE_DELIVERY_TYPE,
  SALE_DELIVERY_TYPE_HEADER,
  SALE_ORDER_ID,
  SALE_ORDER_ID_HEADER,
  SALE_SERVICE_TYPE,
  SALE_SERVICE_TYPE_HEADER,
  SALE_STATUS,
  SALE_STATUS_HEADER
} from '../../../../constants/sales'
import { DATE, STRING } from '../../../../constants/data_types'

import OrderRow from './OrderRow'

const StoreOrdersList = ({
  orders,
  setOrders,
  searchResult,
  setSearchResult,
  page,
  setPage,
  pageSize,
  setPageSize
}) => {
  const navigate = useNavigate()

  if (!searchResult) {
    return (
      <div className="h-64 flex justify-center items-center">
        <h1 className="text-2xl font-bold text-gray-500">No hay ordenes</h1>
      </div>
    )
  }

  if (searchResult.length === 0) {
    return (
      <div className="h-64 flex justify-center items-center">
        <h1 className="text-2xl font-bold text-gray-500">No hay ordenes para los filtros seleccionados</h1>
      </div>
    )
  }

  const handleSelectOrder = (order) => {
    navigate(`${ROUTES.SALES}/${order.IDBULTO}`, {
      state: {
        previousRoute: window.location.pathname + window.location.search
      }
    })
  }

  const headers = [
    { name: SALE_ORDER_ID_HEADER, value: SALE_ORDER_ID, type: STRING },
    { name: SALE_CUSTOMER_HEADER, value: SALE_CUSTOMER, type: STRING},
    { name: SALE_STATUS_HEADER, value: SALE_STATUS, type: STRING },
    { name: SALE_DELIVERY_TYPE_HEADER, value: SALE_DELIVERY_TYPE, type: STRING },
    { name: SALE_SERVICE_TYPE_HEADER, value: SALE_SERVICE_TYPE, type: STRING },
    { name: SALE_DATE_HEADER, value: SALE_DATE, type: DATE }
  ]

  const handleSort = (sortValues, order) => {
    const sortedSearchedOrders = sortArrayByKey(searchResult, sortValues, order)
    setSearchResult(sortedSearchedOrders)

    const sortedOrders = sortArrayByKey(orders, sortValues, order)
    setOrders(sortedOrders)
  }

  const currentOrders = searchResult.slice((page - 1) * pageSize, page * pageSize)

  return (
    <>
      <Table
        headers={headers.map((header) => header.name)}
        sortValues={Object.fromEntries(headers.map((header) =>
          [header.name, { key: header.value, type: header.type }]
        ))}
        sortData={handleSort}
        isLoading={false}
        error={false}
        roundedTop
        fullWidth={1}
      >
        {currentOrders.map((order) => (
          <OrderRow
            key={order.CODBULTOCLIENTE}
            order={order}
            headers={headers}
            handleSelectOrder={handleSelectOrder}
          />
        ))}
      </Table>
      <div className="h-16 flex place-content-end mx-10">
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsCount={searchResult.length}
        />
      </div>
    </>
  )
}

export default StoreOrdersList
