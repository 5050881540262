import { React, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import RawLoader from '../components/shared/Loader/RawLoader'
import { LOGOS, keyIcon, SHOW_EYE } from '../constants/icons'
import { LOGIN_URL } from '../constants/urls'
import useSession from '../hooks/useSession'
import randomApi from '../axiosConfig/randomApi'
import { LOGIN_ERROR } from '../constants/messages'

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(
    localStorage.getItem('email') ? localStorage.getItem('email') : ''
  )
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(false)
  const { login } = useSession()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')

  // Fetch and save token
  const fetchToken = async () => {
    setLoading(true)
    const body = { email, password }
    try {
      const loginResponse = await randomApi().post(LOGIN_URL, body)

      if (!loginResponse.data.suspended) {
        login(loginResponse.data)
        if (remember) localStorage.setItem('email', email)
      } else {
      throw new Error(LOGIN_ERROR.ACCOUNT_SUSPENDED)
      }
    } catch (e) {
      setEmail('')
      setPassword('')
      setError(
        e.message === LOGIN_ERROR.ACCOUNT_SUSPENDED
          ? LOGIN_ERROR.ACCOUNT_SUSPENDED
          : LOGIN_ERROR.WRONG_CREDENTIALS
      )
      setLoading(false)
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    fetchToken()
    navigate('/')
  }

  return (
    <div className="flex bg-ultra-light-grey h-screen w-screen justify-center">
      <div className="flex flex-col self-center">
        <div className="flex self-center my-8">
          <img alt="Pinflag" className="h-24" src={LOGOS.logoLogin} />
        </div>
        {loading ? (
          <RawLoader />
        ) : (
          <form className="flex flex-col gap-4 self-center w-96">
            {error && (
              <div
                className={`px-2 text-sm ${
                  error === LOGIN_ERROR.ACCOUNT_SUSPENDED
                    ? 'border-[2px] border-red rounded-lg text-sm text-black p-4'
                    : 'text-dark-grey font-semibold'
                } `}
              >
                {error === LOGIN_ERROR.ACCOUNT_SUSPENDED ? (
                  <>
                    <div className="bg-red text-white text-xs rounded-md w-fit p-1">Atención</div>
                    <div className="mx-8 mt-2 text-xs text-justify">
                      {error.split('tiene un saldo pendiente')[0]}
                      <span className="font-bold">tiene un saldo pendiente</span>
                      {error.split('tiene un saldo pendiente')[1]}
                      <span className="font-bold">finanzas@pinflag.cl</span>
                    </div>
                  </>
                ): error}
              </div>
            )}
            <input
              type="email"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px' }}
              className="text-sm w-full bg-white rounded-full py-2 px-4 focus:outline-normal-pinflag text-ultra-dark-grey"
              placeholder="Correo Electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="grid grid-cols-2 w-full">
              <input
                type={showPassword ? 'text' : 'password'}
                style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px' }}
                className="col-start-1 col-end-3 row-start-1 text-sm w-full bg-white rounded-full py-2 px-4 focus:outline-normal-pinflag text-ultra-dark-grey"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="col-start-2 col-end-3 row-start-1 self-center justify-self-end mx-2"
                onClick={() => setShowPassword(!showPassword)}
              >
                <img
                  src={showPassword ? SHOW_EYE.hide : SHOW_EYE.show}
                  alt="mostrar contraseña"
                  className="h-8 opacity-60"
                />
              </button>
            </div>
            <label className="text-sm flex text-dark-grey content-center" htmlFor="remember">
              <input
                className="self-center"
                type="checkbox"
                value={remember}
                onChange={() => setRemember(!remember)}
              />
              <span className="ml-1 self-center">Recordarme</span>
            </label>
            <button
              type="submit"
              className="bg-normal-pinflag hover:opacity-80 text-white font-semibold rounded-full py-2 px-4 hover:bg-hover-pinflag"
              onClick={handleClick}
            >
              Ingresar
            </button>
            <Link to="/pass/recuperar" className="text-form">
              <div className="flex text-sm">
                <img className="h-4 self-center opacity-40 mr-1" alt="icon" src={keyIcon} />
                <span className="self-center text-dark-grey">¿Olvidaste tu contraseña?</span>
              </div>
            </Link>
          </form>
        )}
      </div>
    </div>
  )
}

export default Login
