import React, { useContext, useEffect, useState } from 'react'

import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import Button from '../../../components/shared/Button'
import StoreMatching from './StoreMatching'
import { addFileIcon, exportIcon, LOGOS, pointsActive, STOCKS_ICONS } from '../../../constants/icons'
import { getCompanyStoresLists, getStoresXlsx } from '../../../helpers/request/stocks'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'
import useFetch from '../../../hooks/useFetchParams'
import { downloadFileUrl } from '../../../utils/files'
import AddStores from './AddStores'

const StoreSynchronization = () => {
  const {
    storeSynchSubStep,
    setStoreSynchSubStep,
    setStep,
    pinflagStores,
    setPinflagStores,
    posStores,
    setPosStores,
    cmsStores,
    setCmsStores,
    setStoresLeft,
    setSelectedPinflagStore,
    companyId,
    locations,
    setLocations,
    personalization
  } = useContext(StockConfigurationContext)

  const [openAddStoresModal, setOpenAddStoresModal] = useState(false)

  const { isLoading, error } = useFetch(getCompanyStoresLists, setLocations, companyId)

  const currentPosProvider = personalization.stockFlow.origin

  useEffect(() => {
    if (locations.length === 0) return
    const loadedPosStores = locations.origin.locations
    const loadedPinflagStores = locations.storeWarehouses.locations
    const loadedCmsStores = locations.destination.locations

    setPosStores(loadedPosStores)
    setCmsStores(loadedCmsStores)
    setPinflagStores(loadedPinflagStores)
    setStoresLeft(loadedPinflagStores.length)

    if (loadedPinflagStores.length > 0) {
      setSelectedPinflagStore(loadedPinflagStores[0])
    }
  }, [
    setPinflagStores,
    setPosStores,
    setCmsStores,
    setStoresLeft,
    setSelectedPinflagStore,
    locations,
    setLocations
  ])

  const handleAddStores = () => {
    setOpenAddStoresModal(true)
  }

  const handleDownloadStoresFile = async () => {
    const storesXlsxResponse = await getStoresXlsx(companyId)

    downloadFileUrl(storesXlsxResponse.xlsxUrl)
  }

  const handleStoreMatchingFinished = () => {
    setStep(4)
  }

  return (
    <>
      {isLoading && <RawLoader />}
      {error && <LoadingError />}
      {!isLoading && !error && (
      <div className="flex flex-col h-screen w-full items-center">
        {storeSynchSubStep === 0 && (
          <>
            <div className="flex flex-col w-full mt-4 space-y-4 items-center bg-general-background p-12 rounded-lg">
            <img src={pointsActive} alt="pointsActive" className="h-10" />
              <div className="text-center text-sm">
                Tienes
                {pinflagStores.length === 1 ? ' una tienda ' : ` un total de ${pinflagStores.length} tiendas `}
                en Pinflag
              </div>
              <div className="flex items-center">
                <img src={exportIcon} alt="download" className="h-4 w-4" />
                <Button
                  textColor="text-blue-500"
                  border=""
                  onClick={() => handleDownloadStoresFile()}
                >
                  Descargar plantilla de tiendas
                </Button>
              </div>
            </div>
            <div className="flex justify-center mt-6 space-x-4">
              <Button
                color="bg-white"
                border="border border-normal-pinflag"
                textColor="text-normal-pinflag"
                onClick={handleAddStores}
                className="text-white"
              >
                <div className="flex justify-center items-center">
                  <img src={addFileIcon} alt="upload" className="h-4 w-4 mx-2" />
                  Agregar tiendas
                </div>
              </Button>
              <Button
                color="bg-normal-pinflag"
                onClick={() => setStoreSynchSubStep(1)}
                className="text-white"
              >
                Confirmar y Sincronizar
              </Button>
            </div>
            <AddStores
              open={openAddStoresModal}
              setOpen={setOpenAddStoresModal}
              companyId={companyId}
            />
          </>
        )}

        {storeSynchSubStep === 1 && (
          <Button
            color="bg-general-background"
            textColor="w-full text-black h-68"
            onClick={() => setStoreSynchSubStep(2)}
          >
            <div className="flex flex-col items-center justify-center p-4 rounded-lg h-full">
              <div className="flex flex-col items-center justify-center">
                <div className="flex h-48 items-center justify-center mx-8">
                  <div className="flex flex-col items-center">
                    <div className="flex border rounded-full p-2 m-2 bg-white h-32 w-32 shadow-md items-center justify-center">
                      <img src={STOCKS_ICONS[currentPosProvider]} alt="logo" className="h-12" />
                    </div>
                    <p className="text-black mb-2">{posStores.length} tiendas</p>
                  </div>

                  <div className="w-24 h-1 border-t border-dotted border-gray-400"/>

                  <div className="flex flex-col items-center">
                    <div className="flex border rounded-full p-2 m-2 bg-white h-32 w-32 shadow-md items-center justify-center">
                      <img src={STOCKS_ICONS.shopify} alt="logo" className="h-8" />
                    </div>
                    <p className="text-black mb-2">{cmsStores.length} tiendas</p>
                  </div>

                  <div className="w-24 h-1 border-t border-dotted border-gray-400"/>

                  <div className="flex flex-col items-center">
                    <div className="flex border rounded-full p-2 m-2 bg-white h-32 w-32 shadow-md items-center justify-center">
                      <img src={LOGOS.logoLogin} alt="logo" className="h-12" />
                    </div>
                    <p className="text-black mb-2">{pinflagStores.length} tiendas</p>
                  </div>
                </div>
              </div>
            </div>
          </Button>
        )}

        {storeSynchSubStep === 2 && (
          <StoreMatching onMatchingFinished={handleStoreMatchingFinished} />
        )}
      </div>
      )}
    </>
  )
}

export default StoreSynchronization
