/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

import RawLoader from '../Loader/RawLoader'
import LoadingError from '../LoadingError'

export const Table = ({
  headers,
  sortValues = {},
  sortData,
  maxHeight,
  children,
  isLoading,
  error,
  roundedTop,
  minHeight,
  fullWidth = false
}) => {
  const [order, setOrder] = useState('asc')
  const [sortedField, setSortedField] = useState('')

  const handleSort = (header) => {
    let newOrder
    if (sortedField === header) {
      newOrder = order === 'asc' ? 'desc' : 'asc'
    } else {
      newOrder = 'asc'
    }
    setSortedField(header)

    sortData(sortValues[header], newOrder)
    setOrder(newOrder)
  }


  if (isLoading) {
    return (
      <div
        className={`mx-10 border rounded-b ${
          roundedTop ? 'rounded-t' : ''
        } text-center py-8 bg-white text-s`}
      >
        <RawLoader />
      </div>
    )
  }

  if (error) {
    return (
      <div
        className={`mx-10 border rounded-b ${
          roundedTop ? 'rounded-t' : ''
        } text-center py-8 bg-white text-s`}
      >
        <LoadingError />
      </div>
    )
  }

  return (
    <div
      className={`overflow-x-auto border rounded-b ${minHeight ? 'min-h-[40vh]': ''} ${maxHeight || ''} ${
        roundedTop ? 'rounded-t' : ''} ${fullWidth ? 'w-full' : 'mx-10 '}`}
    >
      <table className="text-s text-medium-dark-grey w-full">
        <thead className="w-full">
          <tr>
            <th className="w-2" />
            {headers.map((header, index) => (
              <th key={index} className="text-left font-normal px-2 py-3 whitespace-nowrap">
                <div className="relative flex gap-1">
                  {header}
                  {sortValues[header] && (
                    <button
                      className="flex flex-col"
                      type="button"
                      onClick={() => handleSort(header)}
                    >
                      <svg
                        className={`absolute top-0 w-4 h-4 ${
                          sortedField === header && order === 'asc'
                            ? 'fill-ultra-dark-grey'
                            : 'fill-dark-grey'
                        }`}
                      >
                        <path d="M4.90002 9.79999L8.40002 6.29999L11.9 9.79999H4.90002Z" />
                      </svg>
                      <svg
                        className={`absolute top-0.5 w-4 h-4 ${
                          sortedField === header && order === 'desc'
                            ? 'fill-ultra-dark-grey'
                            : 'fill-dark-grey'
                        }`}
                      >
                        <path d="M8.40002 13.7L4.90002 10.2H11.9L8.40002 13.7Z" />
                      </svg>
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y border-y overflow-auto">{children}</tbody>
      </table>
    </div>
  )
}

export const TableRow = ({ items, onClick, classNames, excludeHoverIndex = -1 }) => {
  const [disableHover, setDisableHover] = useState(false)

  const handleClick = (event) => {
    if (event.target.closest('a, button, input')) return
    if (!onClick) return
    onClick()
  }

  const handleMouseEnter = (index) => {
    if (index === excludeHoverIndex  && items[index].props.children !== false) {
      setDisableHover(true)
    }
  }

  const handleMouseLeave = (index) => {
    if (index === excludeHoverIndex ) {
      setDisableHover(false)
    }
  }

  return (
    <tr className={`w-full ${onClick ? 'group cursor-pointer' : ''} ${!disableHover ? 'hover:bg-light-grey' : ''}`}>
      <td className="w-2" onClick={handleClick} />
      {items.map((item, index) => (
        <td
          onClick={handleClick}
          key={index}
          className={`text-left  ${index === excludeHoverIndex  ? '' : 'p-2'} whitespace-nowrap ${classNames && classNames[index]}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          <div className="flex items-center gap-2">{![null, undefined].includes(item) ? item : '-'}</div>
        </td>
      ))}
    </tr>
  )
}
