import React, { useState, useCallback, useMemo } from 'react'
import secureLocalStorage from 'react-secure-storage'

import SectionHeader from '../../components/shared/SectionHeader'
import { getPersonalization, postPersonalization } from '../../helpers/request/personalization'
import { getCompanyConfiguration, putCompanyConfiguration } from '../../helpers/request/companyConfiguration'
import useFetch from '../../hooks/useFetchParams'
import RawLoader from '../../components/shared/Loader/RawLoader'
import LoadingError from '../../components/shared/LoadingError'
import Button from '../../components/shared/Button'
import TaxDocumentsEditBox from '../../components/Settings/Payments/TaxDocumentsEditBox'
import PaymentGatewayEditBox from '../../components/Settings/Payments/PaymentGatewayEditBox'

const PaymentsConfiguration = () => {
  const [personalization, setPersonalization] = useState({})
  const [companyConfiguration, setCompanyConfiguration] = useState({})

  const [isUpdated, setIsUpdated] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(null)

  const companyId = secureLocalStorage.getItem('companies').sort((a, b) => a.id - b.id)[0].id

  const initialChanges = useMemo(
    () => ({ personalization: {}, configuration: {} }),
    []
  )

  const [paymentChanges, setPaymentChanges] = useState(initialChanges)
  const [taxDocumentChanges, setTaxDocumentChanges] = useState(initialChanges)

  const {
    isLoading: isPersonalizationLoading,
    error: personalizationError
  } = useFetch(getPersonalization, setPersonalization, companyId)

  const {
    isLoading: isCompanyConfigurationLoading,
    error: companyConfigurationError
  } = useFetch(getCompanyConfiguration, setCompanyConfiguration, companyId)

  // eslint-disable-next-line
  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    setError(null)

    try {
      if (paymentChanges.personalization?.payment) {
        await postPersonalization(companyId, {
          companyId,
          payment: {
            ...personalization.payment,
            ...paymentChanges.personalization.payment
          }
        })
      }

      if (Object.keys(paymentChanges.configuration || {}).length > 0) {
        await putCompanyConfiguration(companyId, {
          ...paymentChanges.configuration,
          companyId
        })
      }

      if (taxDocumentChanges.personalization) {
        if (taxDocumentChanges.personalization.taxDocumentEmission) {
          await postPersonalization(companyId, {
            companyId,
            taxDocumentEmission: {
              active: taxDocumentChanges.personalization.taxDocumentEmission.active
            }
          })
        }

        if (taxDocumentChanges.personalization.cmsActiveBillingFields) {
          await postPersonalization(companyId, {
            companyId,
            cmsActiveBillingFields: taxDocumentChanges.personalization.cmsActiveBillingFields
          })
        }
      }

      if (Object.keys(taxDocumentChanges.configuration || {}).length > 0) {
        await putCompanyConfiguration(companyId, {
          ...taxDocumentChanges.configuration,
          companyId
        })
      }

      // Refresh data after successful submit
      const [newPersonalization, newConfiguration] = await Promise.all([
        getPersonalization({ params: companyId }),
        getCompanyConfiguration({ params: companyId })
      ])

      setPersonalization(newPersonalization)
      setCompanyConfiguration(newConfiguration)

      // Reset states
      setSubmitted(true)
      setTimeout(() => setSubmitted(false), 3000)
      setPaymentChanges(initialChanges)
      setTaxDocumentChanges(initialChanges)
      setIsUpdated(false)
    } catch (err) {
      setError('Error al guardar los cambios. Por favor intente nuevamente.')
    } finally {
      setIsSubmitting(false)
    }
  }, [companyId, paymentChanges, taxDocumentChanges, personalization.payment, initialChanges])

  const handleCancel = useCallback(() => {
    setPaymentChanges(initialChanges)
    setTaxDocumentChanges(initialChanges)
    setIsUpdated(false)
  }, [initialChanges])

  if (isPersonalizationLoading || isCompanyConfigurationLoading) {
    return (
      <div className="loader flex h-full items-center justify-center">
        <RawLoader />
      </div>
    )
  }

  if (personalizationError || companyConfigurationError) {
    return (
      <div className="loader flex h-full items-center justify-center">
        <LoadingError />
      </div>
    )
  }

  return (
    <div className="flex-1 p-8 bg-light-grey">
      <div className="mb-8 border border-gray rounded-md bg-white">
        <SectionHeader title="Pagos" />
      </div>

      <div className="flex flex-col bg-white p-8 border border-gray rounded-md gap-8">
        <PaymentGatewayEditBox
          personalization={personalization}
          configuration={companyConfiguration}
          onUpdate={(changes) => {
            const changesMade = {
              personalization: changes.personalization ? {
                payment: {
                  ...changes.personalization.payment
                }
              } : {},
              configuration: changes.configuration ? {
                ...changes.configuration
              } : {}
            }

            setPaymentChanges(changesMade)
            setIsUpdated(true)
          }}
          onCancel={handleCancel}
          isUpdated={isUpdated}
          changes={paymentChanges}
          setChanges={setPaymentChanges}
        />

        <TaxDocumentsEditBox
          personalization={personalization}
          configuration={companyConfiguration}
          onUpdate={(changes) => {
            const changesMade = {
              personalization: changes.personalization ? {
                ...changes.personalization
              } : {},
              configuration: changes.configuration ? {
                ...changes.configuration
              } : {}
            }

            setTaxDocumentChanges(changesMade)
            setIsUpdated(true)
          }}
          onCancel={handleCancel}
          isUpdated={isUpdated}
          changes={taxDocumentChanges}
          setChanges={setTaxDocumentChanges}
        />

        {isUpdated && !isSubmitting && (
          <div className="flex gap-2 justify-center">
            <Button
              color="bg-normal-pinflag"
              onClick={handleSubmit}
              small
            >
              Guardar
            </Button>
            <Button
              color="bg-dark-grey"
              onClick={handleCancel}
              small
            >
              Cancelar
            </Button>
          </div>
        )}

        {isSubmitting && (
          <div className="flex justify-center">
            <RawLoader />
          </div>
        )}

        {submitted && (
          <div className="flex justify-center">
            <div className="text-normal-pinflag">¡Guardado exitoso!</div>
          </div>
        )}

        {error && (
          <div className="flex justify-center">
            <div className="text-red-500">{error}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentsConfiguration
