import React, { useState } from 'react'
// eslint-disable-next-line
const EditBox = ({ title, description, children, rightItem, width, className, defaultOpen = true }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  return (
    <div className={`rounded-md ${width || 'w-80'}`}>
      <div
        role="button"
        tabIndex={0}
        className="text-ultra-dark-grey font-medium text-sm p-4 border border-gray-200 rounded-t-md flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsOpen(!isOpen)
          }
        }}
      >
        {rightItem ? (
          <div className="flex justify-between w-full">
            <div>{title}</div>
            {rightItem}
          </div>
        ) : (
          <div>{title}</div>
        )}
        <div className="ml-2">
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
          >
            <path d="M1 1L7 7L13 1" stroke="#505050" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
      {isOpen && (
        <div className={`border border-t-0 rounded-b-md p-4 ${className || ''}`} style={{ boxShadow: 'inset 0px 8px 12px -8px rgba(50, 50, 50, 0.2)' }}>
          {description && <div className="text-dark-grey text-xs p-4">{description}</div>}
          <div className="text-sm text-ultra-dark-grey p4">{children}</div>
        </div>
      )}
    </div>
  )
}

export default EditBox
