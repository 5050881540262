export const API_URL = process.env.REACT_APP_API_URL

export const OPS_URL = process.env.REACT_APP_OPS_URL

export const COMPANY_API_V2_URL = process.env.REACT_APP_COMPANY_API_V2_URL

export const STATS_API_URL = process.env.REACT_APP_STATS_API_URL

export const MOCK_API_URL = process.env.REACT_APP_MOCK_API_URL

export const TRACKING_URL = process.env.REACT_APP_TRACKING_URL

export const PINFLAG_BOT_URL = process.env.REACT_APP_PINFLAG_BOT_URL

export const GOOGLE_API_URL = process.env.REACT_APP_GOOGLE_API_URL

export const STATES_URL = '/states/cities'

export const LOGIN_URL = '/auth/login-company'

export const WAREHOUSES_URL = '/company/warehouses'

export const WAREHOUSE_URL = (WAREHOUSE_ID) => `/warehouses/${WAREHOUSE_ID}`

export const SALE_URL = (SALE_ID) => `/sales/${SALE_ID}`

export const PACKING_URL = '/sales/packing-list'

export const PICKING_URL = '/packages/picking'

export const PACKAGES_URL = '/packages'

export const UPDATE_PACKAGE_URL = (packageId) => `/packages/${packageId}`

export const RACK_QR_URL = '/warehouses/qr'

export const ALL_COURIERS = '/couriers'

export const COURIERS_BY_STATE_URL = '/courier-service/states'

export const COMPANY_COURIERS_URL = '/courier-service/states/holding'

export const PROFILE_URL = '/companies'

export const RESET_PASS_URL = '/reset-password'

export const CROSS_APPROVAL_URL = '/approve'

export const CROSS_APPROVAL_UPDATE = (COMPANY_ID) => `/approve/${COMPANY_ID}/company`

export const CROSS_APPROVAL_MAPPOINTS = (COMPANY_ID) =>
  `/map-data/${COMPANY_ID}/warehouses`

export const ORDER_CREATION_URL = '/company/package'

export const COMPANY_PERSONALIZATION_URL = (COMPANY_ID) =>
  `/companies/${COMPANY_ID}/personalization`

export const CUSTOM_EMAIL_PREVIEW_URL = (COMPANY_ID) =>
  `/companies/${COMPANY_ID}/personalization/email/preview`

export const MANIFESTS_URL = '/manifest'

export const MANIFEST_URL = (MANIFEST_ID) => `/manifest/${MANIFEST_ID}`

export const MANIFEST_CLOSE_URL = (MANIFEST_ID) => `/manifest/${MANIFEST_ID}/close`

export const SHIPPINGS_URL = '/shipping'

export const TICKETS_URL = '/tickets'

export const TICKET_URL = (TICKET_ID) => `/tickets/${TICKET_ID}`

export const REOPEN_TICKET = (TICKET_ID) => `/tickets/${TICKET_ID}/reopen`

export const TICKET_BY_ID = (PINFLAG_ID) => `/tickets/pinflagId/${PINFLAG_ID}`

export const CLOSE_TICKET= (TICKET_ID) => `/tickets/${TICKET_ID}/close`

export const COURIER_LOGS = (PACKAGE_ID) => `/packages/${PACKAGE_ID}/courier/logs`

export const PACKAGE_IN_TRANSIT = '/company/package/status/in-transit'

export const PACKAGE_RECEPTION = '/companies/couriers/package/reception'

export const PACKAGE_DELIVERED = '/companies/couriers/package/delivered'

export const PACKAGE_CANCELLED = '/companies/couriers/package/cancel'

export const PACKAGE_PAYMENT_REFUND = '/sale-refund'

export const REFUND_BY_PACKAGE_ID = (PACKAGE_ID) => `/sale-refund/package/${PACKAGE_ID}`

export const RULES_URL = '/rules'

export const CREATE_RULE_URL = (COMPANY_ID) => `/rules/company/${COMPANY_ID}`

export const RULE_URL = (RULE_ID) => `/rules/${RULE_ID}`

export const EDIT_RULE_URL = (RULE_ID, COMPANY_ID) =>
  `/rules/${RULE_ID}/company/${COMPANY_ID}`

export const RULE_TOGGLE = (RULE_ID) => `/rules/${RULE_ID}/toggle`

export const RULE_URL_CONFLICT = '/rules/conflicts'

export const SEND_MESSAGE_URL = (TICKET_ID) => `${TICKETS_URL}/${TICKET_ID}/messages`

export const WAITING_TIMES_URL = '/waiting-times'

export const NOTIFICATIONS_URL = '/notification'

export const NOTIFICATIONS_URL_OPS = '/notifications'

export const READ_NOTIFICATION = (notificationId) =>
  `/notification/${notificationId}/read`

export const EXPORT_SALES = '/companies/packages/xlsx'

export const MULTIVENDE_CODE = '/multivende/store'

export const MULTI_PACKAGE_LABEL = '/labels/multi-package'

export const UPDATE_FEE = (COMPANY_ID) => `/fee/${COMPANY_ID}`

export const COURIER_CONFIGURATION = '/company/courier-configuration'

export const LAVELARY_URL = 'https://api.labelary.com/v1/graphics'

export const UPDATE_PACKAGE_WITH_ERROR_URL = (packageId) => `/packages/${packageId}/package-with-error`
