import React, { useState } from 'react'

import apiClient from '../../../axiosConfig/apiClient'
import randomApi from '../../../axiosConfig/randomApi'
import { iconCircle, infoWhite } from '../../../constants/icons'
import { UPDATE_PACKAGE_URL } from '../../../constants/urls'
import { parseBodyGetLabel } from '../../../helpers/sales'
import { downloadFileUrl } from '../../../utils/files'
import ChangeAddressForm from '../../Sales/SalesDetails/ModifyPackage/ChangeAddressForm'
import Button from '../../shared/Button'
import RawLoader from '../../shared/Loader/RawLoader'
import Modal from '../../shared/Modal/Modal'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'

const ChangeAddressTicket = ({
  isOpen,
  handleClose,
  packageId,
  saleData,
  setInputSaleOrder,
  setChangeAddressBoughtOrder,
  setChangeAddressProcessedOrder,
  isProcessed,
  packageWithError = false
}) => {
  const transformedData = {
    address: saleData.deliveryAddress,
    cityId: saleData.cityId,
    deliveryInstructions: saleData.deliveryInstructions,
    customer: {
        name: saleData.clientName,
        lastname: saleData.clientLastName,
        email: saleData.clientEmail,
        phone: saleData.clientPhone,
        ssn: saleData.ssn
    }
  }
  const [editing, setEditing] = useState({ personal: false, shipping: false })
  const [isLoading, setIsLoading] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [openBoughtOrderModal, setOpenBoughtOrderModal] = useState(false)
  const [openProcessedOrderModal, setOpenProcessedOrderModal] = useState(false)
  const [isLoadingLabel, setIsLoadingLabel] = useState(false)
  const [errorResponse, setErrorResponse] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])
  const [showFinalModal, setShowFinalModal] = useState(false)

  const makeBody = (data) => {
    let body = { ...data }
    if (!editing.personal) delete body.customer
    if (!editing.shipping) body = { customer: data.customer }
    return { package: body }
  }

  const getOrderFile = async (selectedSales) => {
    if (selectedSales.length === 0) return
    setIsLoadingLabel(true)
    const body = parseBodyGetLabel(selectedSales)
    try {
      const response = await apiClient.post('/labels', body)
      downloadFileUrl(response.data.all_labels_url)
      setShowFinalModal(true)
    } catch (error) {
      setErrorMessage(JSON.stringify(error.response?.data || error.response || error))
      setErrorResponse(true)
    } finally {
      setIsLoadingLabel(false)
    }
  }

  const onSubmit = async (data) => {
    if (!editing.personal && !editing.shipping) return
    const body = makeBody(data)
    setIsLoading(true)
    try {
      await randomApi().put(UPDATE_PACKAGE_URL(packageId), body)
      setSubmitError(false)
      handleClose()
      setInputSaleOrder('')
      setChangeAddressBoughtOrder(false)
      if (!isProcessed) {
        setOpenBoughtOrderModal(true)
      } else if (isProcessed) {
        setOpenProcessedOrderModal(true)
      }
    } catch {
      setSubmitError(true)
    } finally {
      setIsLoading(false)
      setOpenResponseModal(true)
    }
  }

  const handleCloseProcessedOrderModal = (event) => {
    const isFromButton = event?.target?.tagName === 'BUTTON' || event?.target?.getAttribute('role') === 'button'
    if (!isFromButton) return
    setOpenProcessedOrderModal(false)
    setChangeAddressProcessedOrder(false)
    setInputSaleOrder('')
  }

  const handleCloseFinalModal = () => {
    setShowFinalModal(false)
  }

  return (
    <><Modal show={isOpen} handleClose={handleClose}>
      <div className="m-2 text-ultra-dark-grey text-lg pb-2">Editar información de entrega</div>
      <ChangeAddressForm
        saleData={transformedData}
        packageId={packageId}
        packageWithError={packageWithError}
        onSubmitForm={onSubmit}
        isLoading={isLoading}
        clickAndCollect={saleData.deliveryType !== 'homeDelivery'}
        stateId={saleData.stateId}
        city={saleData.city}
        warehouseName={saleData.warehouseName}
        packageStatus={saleData.packageStatus}
        editing={editing}
        setEditing={setEditing}
      />
      {submitError && (
        <ResponseModal
          isModalOpen={openResponseModal}
          handleClose={() => {
            setOpenResponseModal(false)
            setChangeAddressBoughtOrder(false)
          }}
          error={submitError} />
      )}
    </Modal>
    <Modal show={openBoughtOrderModal}
      handleClose={() => {
        setOpenBoughtOrderModal(false)
        setInputSaleOrder('')
      }}>
      <div className="h-40">
        <div className="flex flex-col justify-center items-center my-4 max-w-md text-center space-y-4">
          <img alt="icon" src={iconCircle} className="mx-auto w-14 h-auto"/>
          <div className="font-semibold text-center">Dirección cambiada con éxito</div>
        </div>
        <div className="flex justify-center gap-2 w-full mt-6">
          <Button onClick={() => {
            setOpenBoughtOrderModal(false)
            setInputSaleOrder('')
          }}
          color="bg-normal-pinflag w-full" border=" border-normal-pinflag">
          Listo
          </Button>
        </div>
      </div>
    </Modal>
    <Modal show={openProcessedOrderModal} handleClose={handleCloseProcessedOrderModal}>
      <div className="flex flex-col w-80">
        <div className="flex flex-col justify-center items-center max-w-md text-center space-y-4">
          <img alt="icon" src={iconCircle} className="mx-auto w-14 h-auto"/>
          <div className="font-semibold text-center">Dirección cambiada con éxito</div>
          <div className='flex flex-row bg-semi-light-grey rounded-xl p-2 w-full'>
            <img alt="icon" src={infoWhite} className="w-1/12 h-full pt-0.5 pr-1"/>
            <div className="font-light w-11/12 text-left">Ahora debes descargar la etiqueta para imprimirla y pegarla en el paquete correspondiente</div>
          </div>
        </div>
        <div className="flex justify-center gap-2 w-full mt-6">
          <Button onClick={async () => {
            await getOrderFile([packageId])
            setOpenProcessedOrderModal(false)
            setInputSaleOrder('')
            setChangeAddressProcessedOrder(false)
          }}
          color="bg-normal-pinflag w-full" border=" border-normal-pinflag">
          Descargar
          </Button>
        </div>
      </div>
    </Modal>
    <ResponseModal
      handleClose={() => setErrorResponse(false)}
      isModalOpen={errorResponse}
      errorMessage={errorMessage}
      error
    />
    {isLoadingLabel && (
      <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-900 z-50">
        <RawLoader />
      </div>
    )}
    <Modal show={showFinalModal} handleClose={handleCloseFinalModal}>
      <div className="flex flex-col w-80">
        <div className="flex flex-col justify-center items-center max-w-md text-center space-y-4">
          <img alt="icon" src={iconCircle} className="mx-auto w-14 h-auto"/>
          <div className="font-medium w-11/12 text-center">Listo</div>
        </div>
      </div>
    </Modal>
  </>
  )
}

export default ChangeAddressTicket
