import React, { useState } from 'react'

import { getCompaniesListNames } from '../../../helpers/companies'
import Filters from '../../shared/Filters/Filters'
import { activeChecks, datesHasChanged, filterArray, filterByDate, handleChecked } from '../../../utils/filters'
import { PACKAGE_STATUS_TRANSLATE_DISTINCT } from '../../../constants/packageStatus'
import { STOCK_CRITICAL, STOCK_INACTIVE, STOCK_OPTIMAL, STOCKS } from '../../../constants/stocks'
import { DELIVERY_TYPES } from '../../../constants/sales'
import { SERVICES_TYPES, SERVICES_TYPES_TRANSLATE } from '../../../constants/couriers'

const StoreDetailsFilters = (props) => {
  const { store, handleFilterChange, isFiltered, setIsFiltered, selectedList } = props

  const companies = getCompaniesListNames()
  const [checkCompanies, setCheckCompanies] = useState(new Array(companies.length).fill(false))
  const [checkStockStatus, setCheckStockStatus] = useState(new Array(3).fill(false))
  const [checkOrderStatus, setCheckOrderStatus] = useState(new Array(10).fill(false))
  const [checkDelivery, setCheckDelivery] = useState(
    new Array(Object.keys(DELIVERY_TYPES).length).fill(false)
  )
  const [checkServices, setCheckServices] = useState(
    new Array(Object.keys(SERVICES_TYPES).length).fill(false)
  )

  const today = new Date()
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState(today)

  const handleCompanies = (position) => {
    handleChecked(position, checkCompanies, setCheckCompanies)
  }

  const handleStockStatus = (position) => {
    handleChecked(position, checkStockStatus, setCheckStockStatus)
  }

  const handleOrderStatus = (position) => {
    handleChecked(position, checkOrderStatus, setCheckOrderStatus)
  }

  const handleDeliveryMethods = (position) => {
    handleChecked(position, checkDelivery, setCheckDelivery)
  }

  const handleServices = (position) => {
    handleChecked(position, checkServices, setCheckServices)
  }

  const stockStatusCheckList = [STOCK_OPTIMAL, STOCK_CRITICAL, STOCK_INACTIVE]

  const stockFields = [
    {
      name: 'Empresa',
      checkList: companies,
      handleCheck: handleCompanies,
      checkState: checkCompanies
    },
    {
      name: 'Estado',
      checkList: stockStatusCheckList,
      handleCheck: handleStockStatus,
      checkState: checkStockStatus
    }
  ]

  const orderFields = [
    {
      name: 'Estado',
      checkList: Object.values(PACKAGE_STATUS_TRANSLATE_DISTINCT),
      handleCheck: handleOrderStatus,
      checkState: checkOrderStatus
    },
    {
      name: 'Envío',
      checkList: Object.values(DELIVERY_TYPES),
      handleCheck: handleDeliveryMethods,
      checkState: checkDelivery
    },
    {
      name: 'Servicio',
      checkList: Object.values(SERVICES_TYPES_TRANSLATE),
      handleCheck: handleServices,
      checkState: checkServices
    }
  ]

  const handleSubmit = () => {
    let filteredData
    if (selectedList === 'stocks') {
      filteredData = store.StoreWarehouse.variantStocks

      filteredData = filterArray(filteredData, checkStockStatus, stockStatusCheckList, 'status')
      filteredData = filterArray(filteredData, checkCompanies, companies, 'companyId')
    } else {
      filteredData = store.packages

      if (datesHasChanged(initialDate, finalDate, today)) {
        filteredData = filterByDate(filteredData, 'FECHACOMPRA', initialDate, finalDate)
      }
      filteredData = filterArray(filteredData, checkOrderStatus, Object.keys(PACKAGE_STATUS_TRANSLATE_DISTINCT), 'status')
      filteredData = filterArray(filteredData, checkDelivery, Object.keys(DELIVERY_TYPES), 'deliveryType')
      filteredData = filterArray(filteredData, checkServices, Object.keys(SERVICES_TYPES_TRANSLATE), 'serviceType')
    }

    const activeChecksAssert = activeChecks(checkCompanies) || activeChecks(checkStockStatus) ||
      activeChecks(checkOrderStatus) || datesHasChanged(initialDate, finalDate, today) ||
      activeChecks(checkDelivery) || activeChecks(checkServices)

    setIsFiltered(activeChecksAssert)

    handleFilterChange(filteredData, activeChecksAssert)
  }

  const fields = selectedList === STOCKS ? stockFields : orderFields

  const handleClean = () => {
    setCheckCompanies(new Array(companies.length).fill(false))
    setCheckStockStatus(new Array(3).fill(false))
    setCheckOrderStatus(new Array(10).fill(false))
    setCheckDelivery(new Array(Object.keys(DELIVERY_TYPES).length).fill(false))
    setCheckServices(new Array(Object.keys(SERVICES_TYPES).length).fill(false))
    setInitialDate('')
    setFinalDate(today)
    setIsFiltered(false)
    handleFilterChange(selectedList === STOCKS
      ? store.StoreWarehouse.variantStocks
      : store.packages
    )
  }

  return (
    <Filters
      fields={fields}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      isFiltered={isFiltered}
      allowSingle={1}
      atLeft={1}
      initialDate={selectedList === STOCKS ? '' : initialDate}
      finalDate={selectedList === STOCKS ? '' : finalDate}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
    />
  )
}

export default StoreDetailsFilters
