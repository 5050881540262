import React from 'react'

import { getTimeFromDate, getFormattedDate } from '../../../utils/dates'
import { TICKET_ICONS, greenCheck } from '../../../constants/icons'
import { CIRCLE_STATUS_COLOR } from '../../../constants/tickets'

const TimeLine = ({ statusLogs }) => {

  const getStatusIcon = (status) => {
    if (status === 'Cerrado') return <img src={TICKET_ICONS.closeTicket} alt="Closed" className="w-6"/>
    if (status === 'Resuelto') return <img src={greenCheck} alt="Resolved" className="w-6"/>
    return (
      <span
        className={`inline-flex items-center justify-center rounded-full
          border-2 min-w-[20px] min-h-[20px] ${CIRCLE_STATUS_COLOR[status]}`}
      >
        {' '}
      </span>
    )
  }

  return (
  <div className="relative top-[-2.5rem] ml-10 flex h-20 w-max">
    {statusLogs && (
      <div className="relative flex h-full flex-col">
        {statusLogs.map((status, index) => (
          <div key={status.id} className="relative flex min-h-full flex-col px-1.5 text-left items-center">
            {index !== 0 ? (
              <span className="relative top-0 z-0 h-full w-0 border border-medium-dark-grey">
                {' '}
              </span>
            ) : (
              <span className="relative top-0 z-0 h-full w-0"> </span>
            )}
            {index === statusLogs.length - 1 ? (
                getStatusIcon(status.status)
              ) : (
                <span className='z-10 block h-4 w-4 rounded-full border-4 border-medium-dark-grey bg-medium-dark-grey'>
                  {' '}
                </span>
              )}
            <div className="absolute left-10 top-14 whitespace-nowrap">
              <span className="text-sm font-medium text-medium-dark-grey">{status.status}</span>
              <div className="block text-xs">
                <div>{getFormattedDate(status.updatedAt)}</div>
                <div>{getTimeFromDate(status.updatedAt)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
)}

export default TimeLine
