import React from 'react'

import { STORE_ICONS } from '../../../../constants/icons'

const StoreDimensions = ({ store }) => (
  <div className="w-1/5 flex flex-col bg-white rounded-sm border border-gray-200 p-4 h-auto justify-start">
    <div className="flex text-md text-ultra-dark-grey mb-4 items-center">
      <img src={STORE_ICONS.storage} alt="store-logo" className="h-5 w-5 mr-2" />
      <div>Bodega</div>
    </div>
    <div className="flex mb-2 justify-between">
      <div className="text-sm text-ultra-dark-grey mb-1">Pasillos</div>
      <div className="text-sm text-gray-400 mb-4">{store.PickupPointWarehouse.aisle}</div>
    </div>
    <div className="flex mb-2 justify-between">
      <div className="text-sm text-ultra-dark-grey mb-1">Columnas</div>
      <div className="text-sm text-gray-400 mb-4">{store.PickupPointWarehouse.column}</div>
    </div>
    <div className="flex mb-2 justify-between">
      <div className="text-sm text-ultra-dark-grey mb-1">Pisos</div>
      <div className="text-sm text-gray-400 mb-4">{store.PickupPointWarehouse.height}</div>
    </div>
    <div className="flex mb-2 justify-between">
      <div className="text-sm text-ultra-dark-grey mb-1">Capacidad máxima</div>
      <div className="text-sm text-gray-400 mb-4">{store.PickupPointWarehouse.capacity}</div>
    </div>
  </div>
)

export default StoreDimensions
