import React, { useCallback, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

import { COMPANY_COURIERS_URL } from '../../../constants/urls'
import { joinCouriers } from '../../../helpers/couriers'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'
import CourierItem from '../../../components/Shippings/Couriers/Credentials/CourierItem'
import HeaderTitle from '../../../components/shared/HeaderTitle'
import ROUTES from '../../../constants/routes'
import CredentialsForm from '../../../components/Shippings/Couriers/Credentials/CredentialsForm'
import NoAccess from '../../../components/NoAccess'
import { AuthContext } from '../../../contexts/Store'
import ToggleSwitch from '../../../components/shared/ToggleSwitch/ToggleSwitch.style'
import { COLORS } from '../../../constants/styles'
import { getPersonalization } from '../../../helpers/request/personalization'
import useFetch from '../../../hooks/useFetch'
import useFetchParams from '../../../hooks/useFetchParams'
import useSubmitPersonalization from '../../../components/Settings/useSubmitPersonalization'
import Button from '../../../components/shared/Button'

const Settings = () => {
  const [companyCouriers, setCompanyCouriers] = useState([])
  const [selectedCourier, setSelectedCourier] = useState({})
  const [companyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [personalization, setPersonalization] = useState({})
  const [separateSalesByOrigin, setSeparateSalesByOrigin] = useState(false)

  useEffect(() => {
    if (personalization && personalization.separateSalesByOrigin !== undefined) {
      setSeparateSalesByOrigin(personalization.separateSalesByOrigin)
    }
  }, [personalization])

  const [isUpdated, setIsUpdated] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)
  const navigate = useNavigate()

  const setCompanyCouriersData = useCallback((data) => {
    const couriers = joinCouriers(data)
    setCompanyCouriers(couriers)
  }, [])

  const { isLoading, error } = useFetch(COMPANY_COURIERS_URL, setCompanyCouriersData)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const {
    isLoading: isPersonalizationLoading,
    error: personalizationError,
    success: personalizationSuccess
  } = useFetchParams(getPersonalization, setPersonalization, companyId)

  const { onSubmit, loadingSubmit } = useSubmitPersonalization()

  if (!hasAccess('couriers')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  const handleSubmit = async () => {
    const body = {
      separateSalesByOrigin
    }

    try {
      await onSubmit(companyId, body)
      setPersonalization((prev) => ({
        ...prev,
        separateSalesByOrigin
      }))
      setIsUpdated(false)
    } catch {
      setErrorSubmit(true)
    } finally {
      setSubmitted(true)
    }
  }

  const handleCancel = () => {
    setIsUpdated(false)
    setSubmitted(false)
    setSeparateSalesByOrigin(personalization.separateSalesByOrigin)
  }

  if (loadingResources || isPersonalizationLoading) return <div className="mt-10"><RawLoader /></div>

  return (
    <div className="m-4">
      <HeaderTitle title="Configuración de couriers" goBack={() => navigate(ROUTES.COURIERS)} />
      <div className="mx-10 mt-4">
        Credenciales
        {Object.keys(selectedCourier).length === 0 ? (
          <>
            <div className="mt-4 text-dark-grey text-sm">
              Para configurar las credenciales, primero deberás seleccionar el courier y luego
              ingresar la información de autenticación correspondiente a cada servicio de entrega.
            </div>
            {isLoading && !error && <RawLoader />}
            {error && <LoadingError />}
            {!isLoading && !error && (
              <div className="flex gap-4 justify-left items-center my-8">
                {companyCouriers.map((courier) => (
                  <CourierItem
                    courier={courier}
                    setSelectedCourier={setSelectedCourier}
                    key={courier.id}
                  />
                ))}
              </div>
            )}
            Origen de pedidos
            <div className="mt-4 text-dark-grey text-sm mb-6">
              Configura la visualizacion de tus Ventas desde su origen. En el panel principal
              de Ventas podrás ver por separado los pedidos con origen desde
              el Centro de Distribución y los pedidos desde Tienda.
            </div>
            <div className="flex justify-between items-center border border-gray-200 rounded-lg p-4 text-sm text-dark-grey">
              Habilita o deshabilita tus Ventas por separado desde su origen
              <ToggleSwitch
                size="small"
                inactiveColor={COLORS.LIGHT_GREY}
                check={separateSalesByOrigin}
                setCheck={() => {
                  setSeparateSalesByOrigin(!separateSalesByOrigin)
                  setIsUpdated(true)
                }}
              />
            </div>
          </>
        ) : (
          <CredentialsForm courier={selectedCourier} setCourier={setSelectedCourier} />
        )}
        {(error || personalizationError || errorSubmit) && <LoadingError />}
        {isUpdated && !loadingSubmit && (
          <div className="flex gap-2 justify-center mt-2">
            <Button color="bg-normal-pinflag" onClick={handleSubmit} small>
              Guardar
            </Button>
            <Button color="bg-dark-grey" onClick={handleCancel} small>
              Cancelar
            </Button>
          </div>
        )}
        {(loadingSubmit || isPersonalizationLoading) && <RawLoader />}
        {(submitted && personalizationSuccess) && (
          <div className="flex justify-center mt-2">
            <div className="text-normal-pinflag">¡Guardado exitoso!</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Settings
