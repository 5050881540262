import randomApi from '../../axiosConfig/randomApi'

export const getCompanyStoresLists = async (config) => {
  const { params: companyId } = config

  const response = await randomApi().get(`/stock/${companyId}/locations`)

  return response.data
}

export const getCmsLocations = async (config) => {
  const { params: companyId } = config

  const response = await randomApi().get(`/stock/${companyId}/cms-locations`)

  return response.data.locations
}

export const createLocationMatches = async (companyId, data) => {
  const response = await randomApi().post(
    `/stock/${companyId}/location-matches`,
    data
  )

  return response.data
}

export const recreateCompanyVariants = async (companyId) => {
  const response = await randomApi().post(`/stock/${companyId}/variant/recreate`)

  return response.data
}

export const recreateCompanyStocks = async (companyId) => {
  const response = await randomApi().post(`/stock/${companyId}/recreate`)

  return response.data
}

export const getStoresXlsx = async (companyId) => {
  const response = await randomApi().get(`/stock/${companyId}/stores/xlsx`)

  return response.data
}

export const getStoresTemplate = async (companyId) => {
  const response = await randomApi().get(`/stock/${companyId}/stores/template`)

  return response.data
}

export const uploadStoresXlsx = async (companyId, data) => {
  const response = await randomApi().post(
    `/stock/${companyId}/stores/add`,
    data
  )

  return response.data
}
