import React, { useState } from 'react'

import { downArrow, STORE_ICONS } from '../../../../constants/icons'

import StoreLocation from './StoreLocation'
import StoreCapacity from './StoreCapacity'
import StoreSchedule from './StoreSchedule'
import StoreDimensions from './StoreDimensions'
import { SHIP_FROM_STORE, STORE_PICKUP } from '../../../../constants/stores'
import StoreSLA from './StoreSLA'
import { DELIVERY_TYPES_TRANSLATION } from '../../../../constants/sales'
import { SERVICES_TYPES_TRANSLATE } from '../../../../constants/couriers'
import BaseStoreCard from './BaseStoreCard'
import { getStoreStats } from '../../../../helpers/processStats/storeStats'
import { getCurrentDate } from '../../../../utils/dates'

const StoreDetailsCard = ({ store, type }) => {
  const [isCardExpanded, setIsCardExpanded] = useState(true)

  const storeStats = getStoreStats(store)

  const { month: currentMonth, year: currentYear } = getCurrentDate()


  const toggleCardExpansion = () => {
    setIsCardExpanded(prev => !prev)
  }

  return (
    <div className="flex flex-col w-full bg-light-grey">
      <div
        className={`overflow-hidden transition-max-height duration-300 ease-in-out ${isCardExpanded ? 'max-h-screen' : 'max-h-0'}`}
      >
        {isCardExpanded && (
          <>
            {type === 'Resumen' && (
              <div className="flex flex-row gap-4 p-8">
                <BaseStoreCard
                  cardIcon={STORE_ICONS.packageCount}
                  cardTitle="Total pedidos"
                  cardValue={storeStats.packagesTotal.value}
                  cardPercentage={storeStats.packagesTotal.percentage}
                  cardTimePeriod={currentMonth}
                  customWidth="w-1/4"
                />
                <StoreSLA value={storeStats.sla.value} timePeriod={currentMonth}  />
              </div>
            )}
            {type === DELIVERY_TYPES_TRANSLATION[STORE_PICKUP] && (
              <div className="flex flex-row gap-4 p-8">
                <StoreCapacity store={store}/>
                <BaseStoreCard
                  cardIcon={STORE_ICONS.configuration}
                  cardTitle="Promedio diario de pedidos"
                  cardValue={storeStats[STORE_PICKUP].averageDailyOrders}
                  cardPercentage={storeStats[STORE_PICKUP].averageDailyOrdersPercentage}
                  cardTimePeriod={currentYear}
                />
                <BaseStoreCard
                  cardIcon={STORE_ICONS.time}
                  cardTitle="Tiempo promedio de preparación"
                  cardValue={storeStats[STORE_PICKUP].averagePreparationTime ? `${storeStats[STORE_PICKUP].averagePreparationTime  } hrs` : '-'}
                  cardPercentage={storeStats[STORE_PICKUP].averagePreparationTimePercentage}
                  cardTimePeriod={currentMonth}
                />
                <BaseStoreCard
                  cardIcon={STORE_ICONS.time}
                  cardTitle="Tiempo promedio en espera de retiro"
                  cardValue={storeStats[STORE_PICKUP].averageWaitingTime ? `${storeStats[STORE_PICKUP].averageWaitingTime} hrs` : '-'}
                  cardPercentage={storeStats[STORE_PICKUP].averageWaitingTimePercentage}
                  cardTimePeriod={currentMonth}
                />
                <StoreDimensions store={store}/>
              </div>
            )}
            {type === SERVICES_TYPES_TRANSLATE[SHIP_FROM_STORE] && (
              <div className="flex flex-row gap-4 p-8">
                <StoreCapacity store={store} serviceType={SHIP_FROM_STORE} />
                <BaseStoreCard
                  cardIcon={STORE_ICONS.configuration}
                  cardTitle="Promedio diario de pedidos"
                  cardValue={storeStats[SHIP_FROM_STORE].averageDailyOrders}
                  cardPercentage={storeStats[SHIP_FROM_STORE].averageDailyOrdersPercentage}
                  cardTimePeriod={currentYear}
                />
                <BaseStoreCard
                  cardIcon={STORE_ICONS.time}
                  cardTitle="Tiempo promedio de preparación"
                  cardValue={storeStats[SHIP_FROM_STORE].averagePreparationTime ? `${storeStats[SHIP_FROM_STORE].averagePreparationTime} hrs` : '-'}
                  cardPercentage={storeStats[SHIP_FROM_STORE].averagePreparationTimePercentage}
                  cardTimePeriod={currentMonth}
                />
                <StoreSchedule store={store} serviceType={SHIP_FROM_STORE} />
              </div>
            )}
            {type === 'Otros' && (
              <div className="flex flex-row gap-4 p-8">
                <StoreSchedule store={store}  />
                <StoreLocation store={store} />
              </div>
            )}

          </>
        )}
      </div>
      <div className="flex flex-row py-4 px-4 items-center justify-center">
        <div
          className="h-4 flex flex-row items-center justify-center cursor-pointer"
          onClick={toggleCardExpansion}
          role="button"
          tabIndex="0"
        >
          <img alt="expand-toggle-arrow" src={downArrow} className={`h-4 w-4 ${isCardExpanded ? 'rotate-180' : ''}`} />
          <div className="mx-2">{isCardExpanded ? 'Ver Menos' : 'Ver Más'}</div>
        </div>
      </div>
    </div>
  )
}

export default StoreDetailsCard
