import moment from 'moment'

export const shortDate = (longDate) => (longDate ? longDate.slice(0, 10) : '-')

export const formatDate = (date) => new Date(date).toLocaleString('es-CL', { timeZone: 'America/Santiago' })

export const getFormattedDate = (date) => {
  if (!date) return '-'
  const formattedDate = formatDate(date)
  return shortDate(formattedDate)
}

export const substractDate = (date, days) => {
  let newDate = new Date()
  newDate = date.getDate() - days
  date.setDate(newDate)
  return date.toLocaleDateString('en-CA')
}

export const getTimeFromFormattedDate = (date) => (date ? date.slice(12, 20) : '-')

export const getFormattedDateWithoutTimezone = (date) => {
  if (!date) return '-'

  const formatedDate = moment(date).format('DD-MM-YYYY')

  return formatedDate
}

export const getTimeFromDate = (date) => {
  if (!date) return '-'
  const formattedDate = formatDate(date)
  return getTimeFromFormattedDate(formattedDate)
}

export const getDaysDifference = (date1, date2) => {
  const miliSecondsPerDay = 1000 * 3600 * 24
  return (new Date(date1) - new Date(date2)) / miliSecondsPerDay
}

export const getHourAndDate = (date) => {
  const dateObject = new Date(date)
  return moment(dateObject).format('HH:mm DD/MM')
}

export const removeSecondsFromString = (date) => {
  if (!date) return '-'
  return date.slice(0, 5)
}

export const getFullFormattedStringDate = (date) => {
  if (!date) return '-'
  const options = { weekday: 'long', day: 'numeric', month: 'long' }
  return new Date(date).toLocaleDateString('es-ES', options)
}

export const getCurrentDate = () => {
  const today = new Date()
  const monthNumber = today.getMonth()
  const options = { month: 'long' }

  const day = today.getDate()
  const month = today.toLocaleString('es-ES', options)
  const year = today.getFullYear()

  return {
    day,
    month,
    monthNumber,
    year
  }
}

export const getLastMonth = () => {
  const { monthNumber: currentMonth, year: currentYear } = getCurrentDate()

  const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1
  const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear

  return { lastMonth, lastMonthYear }
}
