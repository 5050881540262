import moment from 'moment'

import { HOME_DELIVERY, SHIP_FROM_STORE, STORE_PICKUP } from '../../constants/stores'
import { getCurrentDate, getLastMonth } from '../../utils/dates'

const packagesForYear = (packages, startDateField) => {
  const today = new Date()
  const currentYear = today.getFullYear()

  const currentYearPackages = packages.filter(packageDetail => {
    const orderDate = new Date(packageDetail[startDateField])
    return orderDate.getFullYear() === currentYear
  })

  const lastYearPackages = packages.filter(packageDetail => {
    const orderDate = new Date(packageDetail[startDateField])
    return orderDate.getFullYear() === (currentYear - 1)
  })

  return {
    currentYearPackages,
    lastYearPackages
  }
}

const getAverageDailyOrders = (packages) => {
  const { currentYearPackages, lastYearPackages } = packagesForYear(packages, 'FECHACOMPRA')
  const { currentYear } = getCurrentDate()
  const daysInCurrentYear = moment().dayOfYear()
  const daysInLastYear = moment().year(currentYear - 1).endOf('year').dayOfYear()

  const currentAverage = currentYearPackages.length / daysInCurrentYear
  const lastYearAverage = lastYearPackages.length / daysInLastYear

  const percentage = lastYearAverage !== 0
    ? ((currentAverage - lastYearAverage) / lastYearAverage) * 100
    : 0

  return { value: Math.round(currentAverage), percentage: percentage.toFixed(2) }
}

const packagesTimeAverage = (packages, startDateField, endDateField) => {
  const filteredPackages = packages.filter(packageDetail => packageDetail[endDateField])
  const average = filteredPackages.reduce((acc, packageDetail) => {
    const diff = new Date(packageDetail[endDateField]) - new Date(packageDetail[startDateField])
    return acc + (diff  / (1000 * 60 * 60))
  }, 0) / (filteredPackages.length || 1)
  return average

}
const packagesForMonth = (packages, startDateField) => {
  const { monthNumber: currentMonth, year: currentYear, day: currentDay } = getCurrentDate()

  const currentMonthStart = new Date(currentYear, currentMonth, 1)
  const currentMonthEnd = new Date(currentYear, currentMonth, currentDay)

  // Crear fechas límite para el mes anterior con el mismo rango de días
  const { lastMonth, lastMonthYear } = getLastMonth()
  const lastMonthStart = new Date(lastMonthYear, lastMonth, 1)
  const lastMonthEnd = new Date(lastMonthYear, lastMonth, currentDay)

  const currentMonthPackages = packages.filter(packageDetail => {
    const orderDate = new Date(packageDetail[startDateField])
    return orderDate >= currentMonthStart && orderDate <= currentMonthEnd
  })

  const lastMonthPackages = packages.filter(packageDetail => {
    const orderDate = new Date(packageDetail[startDateField])
    return orderDate >= lastMonthStart && orderDate <= lastMonthEnd
  })

  return {
    currentMonthPackages,
    lastMonthPackages
  }
}

const getAveragePackageTime = (packages, startDateField, endDateField) => {
  const { currentMonthPackages, lastMonthPackages } = packagesForMonth(packages, startDateField)

  const currentAverage = packagesTimeAverage(currentMonthPackages, startDateField, endDateField)

  const lastMonthAverage = packagesTimeAverage(lastMonthPackages, startDateField, endDateField)

  const percentage = lastMonthAverage !== 0
    ? ((currentAverage - lastMonthAverage) / lastMonthAverage) * 100
    : 0

  return { value: Math.round(currentAverage), percentage: percentage.toFixed(2) }

}

const getAveragePreparationTime = (packages) => getAveragePackageTime(packages, 'FECHACOMPRA', 'inProcessDate')

const getAverageWaitingTime = (packages) => getAveragePackageTime(packages, 'readyForCollectionDate', 'FECHAENTREGA')

const generateDeliveryStats = (packages) => ({
  averageDailyOrders: getAverageDailyOrders(packages).value,
  averageDailyOrdersPercentage: getAverageDailyOrders(packages).percentage,
  averagePreparationTime: getAveragePreparationTime(packages).value,
  averagePreparationTimePercentage: getAveragePreparationTime(packages).percentage,
  averageWaitingTime: getAverageWaitingTime(packages).value,
  averageWaitingTimePercentage: getAverageWaitingTime(packages).percentage
})

const getPackagesTotal = (packages) => {
  const { currentMonthPackages, lastMonthPackages } = packagesForMonth(packages, 'FECHACOMPRA')

  const percentage = lastMonthPackages.length !== 0
  ? ((currentMonthPackages.length - lastMonthPackages.length) / lastMonthPackages.length) * 100
  : 0

  return {
    value: currentMonthPackages.length,
    percentage: percentage.toFixed(2)
  }
}

const getSLA = (packages) => {
  const { currentMonthPackages } = packagesForMonth(packages, 'FECHACOMPRA')

  const filteredPackages = currentMonthPackages.filter(
    packageDetail => packageDetail.readyForCollectionDate || packageDetail.FECHAENTREGA
  )

  const currentMonthCompliant = filteredPackages.filter(packageDetail => {
    const readyDate = packageDetail.readyForCollectionDate || packageDetail.FECHAENTREGA
    return readyDate && new Date(readyDate) <= new Date(packageDetail.FECHAPROGRECEPCION)
  }).length

  const currentSLA = filteredPackages.length > 0
    ? (currentMonthCompliant / filteredPackages.length)
    : 0

  return { value: currentSLA }
}

export const getStoreStats = (store) => {
  const storePickupPackages = store.packages
    .filter((packageDetail) => packageDetail.deliveryType === STORE_PICKUP)
  const shipFromStorePackages = store.packages
    .filter((packageDetail) => packageDetail.deliveryType === HOME_DELIVERY)

  return {
    [STORE_PICKUP]: generateDeliveryStats(storePickupPackages),
    [SHIP_FROM_STORE]: generateDeliveryStats(shipFromStorePackages),
    packagesTotal: getPackagesTotal(store.packages),
    sla: getSLA(store.packages)
  }
}
