import React from 'react'

const OriginTabBar = ({ tabs, selectedTab, setTab, icons }) => (
  <div className="flex relative">
    {tabs.map((tab, index) => (
      <button
        type="button"
        key={tab}
        role="tab"
        aria-selected={selectedTab === tab}
        className={`flex items-center text-sm py-2 px-8 ${
          selectedTab === tab
            ? 'bg-white text-teal-500 font-medium border-t border-l border-r border-gray-200 rounded-t-md z-10'
            : 'bg-gray-100 text-gray-600 border-transparent rounded-t-md'
        }`}
        onClick={() => setTab(tab)}
      >
        <img
          src={icons[index]}
          alt={tab}
          className="w-5 h-5 mr-2"
        />
        <span>{tab}</span>
      </button>
    ))}
  </div>
)

export default OriginTabBar
