import React, { useContext, useEffect, useState } from 'react'

import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import useSubmitPersonalization from '../../../components/Settings/useSubmitPersonalization'
import BsalePosConfigurationForm from '../../../components/Settings/Stock/BsalePosConfigurationForm'
import PrismPosConfigurationForm from '../../../components/Settings/Stock/PrismPosConfigurationForm'
import { BSALE_POS_OPTION, PRISM_POS_OPTION } from '../../../constants/stocks'

const PosConfiguration = () => {
  const {
    configurationSubmitted,
    personalization
  } = useContext(StockConfigurationContext)

  const { onSubmit } = useSubmitPersonalization()

  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (configurationSubmitted && !isEditing) {
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }, [configurationSubmitted, setIsEditing, isEditing])

  const currentPosProvider = personalization.stockFlow.origin

  return (
    <>
      {currentPosProvider === BSALE_POS_OPTION && <BsalePosConfigurationForm onSubmit={onSubmit} />}
      {currentPosProvider === PRISM_POS_OPTION && <PrismPosConfigurationForm onSubmit={onSubmit} />}
    </>
  )
}

export default PosConfiguration
