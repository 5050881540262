import React, { useState, useContext, useEffect } from 'react'

import Button from '../../shared/Button'
import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'

const BsalePosConfigurationForm = ({ onSubmit }) => {
  const {
    personalization,
    setPersonalization,
    companyId,
    step,
    setStep,
    setConfigurationSubmitted,
    setCriticStock,
    setSecurityStock,
    setAccessToken,
    posName,
    accessToken,
    criticStock,
    securityStock
  } = useContext(StockConfigurationContext)

  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [originalValues, setOriginalValues] = useState({
    accessToken: '',
    criticStock: '',
    securityStock: ''
  })

  useEffect(() => {
    if (isEditing) {
      setOriginalValues({
        accessToken: accessToken || '',
        criticStock: criticStock || '',
        securityStock: securityStock || ''
      })
    }
  }, [isEditing, accessToken, criticStock, securityStock])

  const handleModify = () => {
    setIsEditing(true)
  }

  const handleCancel = () => {
    setAccessToken(originalValues.accessToken)
    setCriticStock(originalValues.criticStock)
    setSecurityStock(originalValues.securityStock)

    setIsEditing(false)
  }

  const handleContinue = () => {
    setStep(step + 1)
  }

  const handleSave = async () => {
    setIsSubmitting(true)

    try {
      const body = { stockFlow: { ...personalization.stockFlow, active: true, destination: 'cms' } }

      if (posName) body.stockFlow.origin = posName
      if (accessToken) body.stockFlow.pos_token = accessToken
      if (criticStock) body.stockFlow.critic_stock = criticStock
      if (securityStock) body.stockFlow.security_stock = securityStock

      await onSubmit(companyId, body)
      setPersonalization({ ...personalization, stockFlow: body.stockFlow })
      setConfigurationSubmitted(true)
      setIsEditing(false)
    } catch (error) {
      console.error('Error submitting form:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="flex flex-col mt-4 space-y-4 items-start">
      <div> Token de Acceso</div>
      <input
        className={`border rounded py-2 px-4 mt-2 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setAccessToken(e.target.value)}
        value={accessToken || ''}
        placeholder="Token de acceso"
        disabled={!isEditing}
      />

      <div>Stock Crítico</div>
      <input
        className={`border rounded py-2 px-4 mt-2 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setCriticStock(e.target.value)}
        value={criticStock || ''}
        placeholder="Stock Crítico"
        disabled={!isEditing}
      />

      <div>Stock de Seguridad</div>
      <input
        className={`border rounded py-2 px-4 mt-2 mb-4 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setSecurityStock(e.target.value)}
        value={securityStock || ''}
        placeholder="Stock de Seguridad"
        disabled={!isEditing}
      />

      <div className="flex justify-between w-full space-x-8">
        {isEditing ? (
          <>
            <Button
              color="bg-gray-300 w-full h-14"
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button
              color="bg-normal-pinflag w-full h-14"
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Guardando...' : 'Guardar'}
            </Button>
          </>
        ) : (
          <>
            <Button
              color="bg-normal-pinflag w-full h-14"
              onClick={handleModify}
            >
              Modificar
            </Button>
            {(accessToken || criticStock || securityStock) && (
              <Button
                color="bg-normal-pinflag w-full h-14"
                onClick={handleContinue}
              >
                Continuar
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default BsalePosConfigurationForm
