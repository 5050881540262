import { ASSETS_URL } from './others'

export const SERVICES_TYPES = ['standard', 'express', 'same-day', 'ship-from-store', 'pick-from-store']
export const CHILEXPRESS = 'Chilexpress'

export const SERVICES_TYPES_LIST = [
  { id: 'standard', name: 'Standard' },
  { id: 'express', name: 'Express' },
  { id: 'same-day', name: 'Same Day' },
  { id: 'ship-from-store', name: 'Ship From Store' },
  { id: 'pick-from-store', name: 'Pick From Store' }
]

export const SERVICES_TYPES_DESCRIPTION = [
  { serviceType: 'standard', description: 'Se entrega durante un periodo de días hábiles' },
  { serviceType: 'express', description: 'Se entrega con prioridad' },
  { serviceType: 'same-day', description: 'Se entrega el mismo día' },
  { serviceType: 'ship-from-store', description: 'Envío desde la tienda más cercana' },
  { serviceType: 'distribution', description: 'Envíos para reponer stock en tiendas' },
  { serviceType: 'pick-from-store', description: 'Retiro en tienda con stock de tienda' }
]

export const SERVICES_TYPES_TRANSLATE = {
  standard: 'Standard',
  express: 'Express',
  'same-day': 'Same Day',
  'ship-from-store': 'Ship From Store',
  distribution: 'Distribución',
  'pick-from-store': 'Pick From Store'
}

export const CREDENTIALS_TEMPLATE = `${ASSETS_URL}/couriers/template-credentials.xlsx`
