import React from 'react'

const BaseStoreCard = ({
  cardIcon,
  cardTitle,
  cardValue,
  cardPercentage,
  cardTimePeriod,
  customWidth
}) => (
  <div className={`${customWidth || 'w-1/5'} bg-white rounded-sm border border-gray-200 pb-6 pt-4 px-6 flex flex-col justify-between items-center`}>
    <div className="flex items-start justify-between mb-8">
      <div className="flex items-center">
        <img src={cardIcon} alt="pedidos" className="h-5 w-5 mr-2" />
        <span className="text-gray-700">{cardTitle}</span>
      </div>
    </div>
    <div className="pb-10 flex flex-col items-center">
      <div className="text-4xl text-gray-700">{cardValue}</div>
      {Math.abs(cardPercentage) !== 0 && (
      <div className={`text-sm ml-2 ${cardPercentage > 0 ? 'text-green' : 'text-red'}`}>
          {cardPercentage > 0 ? '↗' : '↙'} {Math.abs(cardPercentage)}%
        </div>
      )}
    </div>
    <div className="text-gray-500 capitalize">{cardTimePeriod}</div>
  </div>
)

export default BaseStoreCard
