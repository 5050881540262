import React, { useCallback, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import { useParams } from 'react-router-dom'

import SectionHeader from '../../../shared/SectionHeader'
import TabBar from '../../../shared/TabBar/TabBar'
import useFetch from '../../../../hooks/useFetchParams'
import { getStore } from '../../../../helpers/request/stores'
import RawLoader from '../../../shared/Loader/RawLoader'
import LoadingError from '../../../shared/LoadingError'
import ROUTES from '../../../../constants/routes'

import Distribution from './Distribution'
import StorePickup from './StorePickup'
import ShipFromStore from './ShipFromStore'
import { TYPE_STORE_SERVICES } from '../../../../constants/stores'

const StoreEdit = () => {
  const { warehouseId } = useParams()

  const [tab = 0, setTab] = useQueryParam('tab', NumberParam)
  const tabs = Object.values(TYPE_STORE_SERVICES)

  const [store, setStore] = useState(null)

  const setPointData = useCallback((data) => {
    setStore(data)
  }, [])

  const reloadStore = useCallback(async () => {
    try {
      const updatedStore = await getStore({ params: warehouseId })

      setStore(updatedStore)
    } catch (error) {
      console.error('Error reloading store data:', error)
    }
  }, [warehouseId])

  const { isLoading, error } = useFetch(getStore, setPointData, warehouseId)

  const previousRoute = `${ROUTES.STORES}/${warehouseId}`

  if (error) return <LoadingError />

  const views = [
    {
      view: <StorePickup store={store} onUpdate={reloadStore} />
    },
    {
      view: <Distribution store={store} onUpdate={reloadStore} />
    },
    {
      view: <ShipFromStore store={store} onUpdate={reloadStore} />
    }
  ]

  return (
    <div>
      {isLoading || !store ? (
        <div className="loader">
          <RawLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-col">
            <SectionHeader
              title="Configuración de tienda/punto"
              previousRoute={previousRoute}
            />
          </div>
          <div className="m-8">
            <TabBar tab={tab} tabs={tabs} setTab={setTab} />
            <div className="my-4">{views[tab].view}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default StoreEdit
