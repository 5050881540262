import { React, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { ticketTypes, DELAY_TYPE, CHANGE_ADDRESS_TYPE } from '../../../constants/tickets'
import Modal from '../../shared/Modal/Modal'
import RawLoader from '../../shared/Loader/RawLoader'
import { getSale } from '../../../helpers/request/sales'
import Button from '../../shared/Button'
import randomApi from '../../../axiosConfig/randomApi'
import { TICKET_BY_ID, REFUND_BY_PACKAGE_ID } from '../../../constants/urls'
import { descriptionIcon, editFile } from '../../../constants/icons'
import OrderInTimeModal from './OrderInTimeModal'
import { PACKAGE_BOUGHT, PACKAGE_PROCESSED } from '../../../constants/packageStatus'
import ChangeAddressTicket from './ChangeAddressTicket'

const ReasonColumn = ({ title, reasonList, handleReason }) => (
  <div className="flex flex-col gap-4">
    <div className="text-sm">{title}</div>
    {reasonList.map((type) => (
      <div
        className="flex gap-2 border rounded w-52 px-2 py-3"
        key={type.text}
        onClick={() => handleReason(type.name)}
        role="button"
        tabIndex="0"
      >
        <img className="w-5 h-5" src={type.icon} alt={type.text} />
        <div className="text-sm">{type.text}</div>
      </div>
    ))}
  </div>
)

const addTicket = (error, inputSaleOrder, setInputSaleOrder, handleSaleOrder, loading) => (
  <div className="p-3">
    <div className="font-medium">Ingresa el código pinflag o la orden de venta para continuar</div>
    <input
      className="border rounded w-full mt-8 p-1"
      value={inputSaleOrder}
      onChange={(e) => setInputSaleOrder(e.target.value)}
    />
    {error && (
      <div className="text-xs text-red">No se ha podido encontrar la orden de venta ingresada</div>
    )}
    <div className="flex justify-end mt-9">
      {loading ? (
        <RawLoader />
      ) : (
        <Button color="bg-normal-pinflag" onClick={handleSaleOrder}>
          Continuar
        </Button>
      )}
    </div>
  </div>
)

const goToTicket = (ticket, setAlreadyHasTicket, setOpenModal) => (
  <div className="flex flex-col justify-center align-center items-center gap-4 w-80 px-4 ">
    <img src={descriptionIcon} className="h-auto w-10" alt="Ya existe ticket" />
    <div className="font-medium text-center">Esta orden ya tiene asignada una solicitud</div>
    <div className="text-center mb-2">Puedes dar seguimiento del caso y actualizarlo</div>
    <div className="flex justify-between w-5/6">
      <button
        type="button"
        onClick={() => {
          setAlreadyHasTicket(null)
          setOpenModal(false)
        }}
        className="opacity-75 hover:opacity-50"
      >
        Volver
      </button>
      <Link to={`/solicitudes/${ticket.id}`}>
      <Button color="bg-normal-pinflag">Ir a la solicitud</Button>
      </Link>
    </div>
  </div>
)

const goToRefund = (refund, setHasRefund, setOpenModal) => (
  <div className="flex flex-col justify-center align-center items-center gap-4 w-80 px-4 ">
    <img src={descriptionIcon} className="h-auto w-10" alt="Ya existe ticket" />
    <div className="font-normal text-center">Esta orden ya tiene asignada una indemnización</div>
    <div className="text-center font-light mb-2">Puedes dirigirte a la indemnización y revisar su estado</div>
    <div className="flex justify-between w-5/6">
      <button
        type="button"
        onClick={() => {
          setHasRefund(null)
          setOpenModal(false)
        }}
        className="opacity-75 hover:opacity-50"
      >
        Volver
      </button>
      <Link to={`/indemnizaciones/${refund.id}`}>
      <Button color="bg-normal-pinflag">Ir a la indemnización</Button>
      </Link>
    </div>
  </div>
)

const goToChangeAddressBoughtOrder = (setOpenModal, setOpenChangeAddressModal,
  setChangeAddressBoughtOrder) => (
  <div className="flex flex-col justify-center align-center items-center gap-4 w-80 px-4 ">
    <img src={editFile} className="h-auto w-10" alt="Cambiar dirección" />
    <div className="font-normal text-center">Puedes directamente cambiar la dirección de esta orden</div>
    <div className="text-center font-light mb-2">El pedido no ha sido procesado por lo que solo debes ingresar los datos de la nueva dirección</div>
    <div className="flex justify-between w-full">
      <button
        type="button"
        onClick={() => {
          setOpenModal(false)
          setChangeAddressBoughtOrder(false)
        }}
        className="opacity-75 hover:opacity-50 mx-5"
      >
        Cancelar
      </button>
      <Button
        onClick={() => {
          setOpenChangeAddressModal(true)
          setOpenModal(false)
        }}
        color="bg-normal-pinflag"
        border="border border-normal-pinflag"
        className="opacity-75 hover:opacity-50">
          <div className="text-base ">Cambiar dirección</div>
      </Button>
    </div>
  </div>
)

const goToChangeAddressProcessedOrder = (setOpenModal, setOpenChangeAddressModal,
  setChangeAddressProcessedOrder) => (
  <div className="flex flex-col justify-center align-center items-center gap-4 w-80 px-4 ">
    <img src={editFile} className="h-auto w-10" alt="Cambiar dirección" />
    <div className="font-normal text-center">Este pedido se encuentra procesado</div>
    <div className="text-center font-light mb-2">Podrás cambiar la dirección pero tendrás que imprimir la nueva etiqueta y pegarla en el paquete correspondiente</div>
    <div className="flex justify-between w-full">
      <button
        type="button"
        onClick={() => {
          setOpenModal(false)
          setChangeAddressProcessedOrder(false)
        }}
        className="opacity-75 hover:opacity-50 mx-5"
      >
        Cancelar
      </button>
      <Button
        onClick={() => {
          setOpenChangeAddressModal(true)
          setOpenModal(false)
        }}
        color="bg-normal-pinflag"
        border="border border-normal-pinflag"
        className="opacity-75 hover:opacity-50">
          <div className="text-base ">Cambiar dirección</div>
      </Button>
    </div>
  </div>
)

const Reasons = ({ reason, setReason, nextStep, sale, setSale }) => {
  const [openModal, setOpenModal] = useState(false)
  const [inputSaleOrder, setInputSaleOrder] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [alreadyHasTicket, setAlreadyHasTicket] = useState(null)
  const [hasRefund, setHasRefund] = useState(null)
  const [openDelayModal, setOpenDelayModal] = useState(false)
  const [saleDetail, setSaleDetail] = useState(null)
  const [changeAddressBoughtOrder, setChangeAddressBoughtOrder] = useState(false)
  const [changeAddressProcessedOrder, setChangeAddressProcessedOrder] = useState(false)
  const [openChangeAddressModal, setOpenChangeAddressModal] = useState(false)
  const [isProcessed, setIsProcessed] = useState(false)

  useEffect(() => {
    setOpenModal(false)
    setInputSaleOrder('')
    setLoading(false)
    setError(false)
    return () => {
      setOpenModal(false)
      setInputSaleOrder('')
      setLoading(false)
      setError(false)
    }
  }, [])

  const handleReason = (reasonName) => {
    setReason(reasonName)
    nextStep()
  }

  const handleOpenModal = (reasonName) => {
    if (Object.keys(sale).length === 0) {
      setReason(reasonName)
      setOpenModal(true)
    } else handleReason(reasonName)
  }

  const handleOthers = (reasonName) => {
    if (
      reasonName === ticketTypes.others[0].name ||
      reasonName === ticketTypes.others[1].name
    ) setSale({})
    handleReason(reasonName)
  }

  const handleTicketStep = async () => {
    const fetchedSale = await getSale(inputSaleOrder)
    setSale(fetchedSale)
    setOpenModal(false)
    nextStep()
  }

  const handleOrderNotDelayed = (fetchedSale) => {
    setSaleDetail(fetchedSale)
    setOpenDelayModal(true)
    setOpenModal(false)
  }

  const handleOrderDelayed = async () => {
    const fetchedSale = await getSale(inputSaleOrder, {params: { origin: 'admin' }})
    const promisedDeliveryDate = new Date(fetchedSale.promisedDeliveryDate)
    const now = new Date()
    if (promisedDeliveryDate > now) {
      handleOrderNotDelayed(fetchedSale)
    } else {
      await handleTicketStep()
    }
  }

  const handleOrderChangeAddress = async () => {
    const fetchedSale = await getSale(inputSaleOrder, {params: { origin: 'admin' }})
    setSaleDetail(fetchedSale)
    if (fetchedSale.packageStatus === PACKAGE_BOUGHT) {
      setIsProcessed(false)
      setChangeAddressBoughtOrder(true)
    } else if (fetchedSale.packageStatus === PACKAGE_PROCESSED) {
      setChangeAddressProcessedOrder(true)
      setIsProcessed(true)
    } else {
      await handleTicketStep()
    }
  }

  const handleNewTicketSale = async () => {
    if (reason === DELAY_TYPE){
      await handleOrderDelayed()
    } else if (reason === CHANGE_ADDRESS_TYPE) {
      await handleOrderChangeAddress()
    } else {
      await handleTicketStep()
    }
  }

  const handleRefundOrder = async () => {
    try {
      const fetchedSale = await getSale(inputSaleOrder)
      const refund = await randomApi().get(REFUND_BY_PACKAGE_ID(fetchedSale.packageId))
      if (!refund.data.error) {
        setHasRefund(refund.data)
        setInputSaleOrder('')
      } else {
        await handleNewTicketSale()
      }
    } catch {
      setError(true)
    }
  }

  const handleSaleOrder = async () => {
    setLoading(true)
    try {
      const fetchedSale = await getSale(inputSaleOrder)
      const ticket = await randomApi().get(TICKET_BY_ID(fetchedSale.pinflagId))
      if (!ticket.data.error) {
        setAlreadyHasTicket(ticket.data)
        setInputSaleOrder('')
      } else {
        await handleRefundOrder()
      }
    } catch (err) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const renderModalContent = () => {
    if (alreadyHasTicket) {
      return goToTicket(alreadyHasTicket, setAlreadyHasTicket, setOpenModal)
    }
    if (hasRefund) {
      return goToRefund(hasRefund, setHasRefund, setOpenModal)
    }
    if (changeAddressBoughtOrder) {
      return goToChangeAddressBoughtOrder(setOpenModal, setOpenChangeAddressModal,
        setChangeAddressBoughtOrder)
    }
    if (changeAddressProcessedOrder) {
      return goToChangeAddressProcessedOrder(setOpenModal, setOpenChangeAddressModal,
        setChangeAddressProcessedOrder)
    }
    return addTicket(error, inputSaleOrder, setInputSaleOrder, handleSaleOrder, loading)
  }

  return (
    <div className="bg-white p-12 rounded-lg h-max w-full">
      <div className="font-medium pb-1">Motivo de la solicitud</div>
      <div className="text-sm pb-6 text-medium-dark-grey">
        Cuéntanos en qué quieres que te ayudemos
      </div>
      <div className="flex flex-wrap gap-10">
        <ReasonColumn
          title="Pre envío"
          reasonList={ticketTypes.preShipping}
          handleReason={handleOpenModal}
        />
        <ReasonColumn
          title="Post envío"
          reasonList={ticketTypes.postShipping}
          handleReason={handleOpenModal}
        />
        <ReasonColumn title="Más" reasonList={ticketTypes.others} handleReason={handleOthers} />
      </div>
      <Modal
        show={openModal}
        handleClose={() => {
          setOpenModal(false)
          setAlreadyHasTicket(null)
          setChangeAddressBoughtOrder(false)
          setChangeAddressProcessedOrder(false)
        }}
      >
        {renderModalContent()}
      </Modal>
      {saleDetail && (
        <OrderInTimeModal
          show={openDelayModal}
          sale={saleDetail}
          handleNextStep={handleTicketStep}
          handleClose={() => {
            setOpenDelayModal(false)
            setInputSaleOrder('')
          }}
        />
      )}
      {saleDetail && (
        <ChangeAddressTicket
          isOpen={openChangeAddressModal}
          saleData={saleDetail}
          handleClose={() => {
            setOpenChangeAddressModal(false)
            setInputSaleOrder('')
            setChangeAddressBoughtOrder(false)
            setChangeAddressProcessedOrder(false)
          }}
          packageId={saleDetail.packageId}
          setInputSaleOrder={setInputSaleOrder}
          setChangeAddressBoughtOrder={setChangeAddressBoughtOrder}
          setChangeAddressProcessedOrder={setChangeAddressProcessedOrder}
          isProcessed={isProcessed}
        />
      )}
    </div>
  )
}

export default Reasons
