import { COLORS } from './styles'

export const STOCK_CONFIGURATION_STEPS = [
  'Empezemos',
  'Pos',
  'Credenciales',
  'Tiendas',
  'Inventario'
]

export const BSALE_POS_OPTION = 'bsale'
export const PRISM_POS_OPTION = 'prism'

export const POS_OPTIONS = [
  {
    value: BSALE_POS_OPTION,
    label: 'Bsale'
  },
  {
    value: PRISM_POS_OPTION,
    label: 'Prism'
  }
]

export const STOCK_OPTIMAL = 'optimal'
export const STOCK_CRITICAL = 'critical'
export const STOCK_INACTIVE = 'inactive'

export const STOCK_STATUS_COLORS = {
  [STOCK_OPTIMAL]: {
    backgroundColor: COLORS.LIGHT_GREEN,
    textColor: COLORS.DARK_GREEN
  },
  [STOCK_CRITICAL]: {
    backgroundColor: COLORS.YELLOW,
    textColor: COLORS.WHITE
  },
  [STOCK_INACTIVE]: {
    backgroundColor: COLORS.RED,
    textColor: COLORS.WHITE
  }
}

export const STOCK_STATUS_TRANSLATE = {
  [STOCK_OPTIMAL]: 'Óptimo',
  [STOCK_CRITICAL]: 'Crítico',
  [STOCK_INACTIVE]: 'Inactivo'
}

export const STOCKS = 'stocks'

export const STOCK_SKU = 'sku'
export const STOCK_NAME = 'name'
export const STOCK_SRC = 'src'
export const STOCK_STATUS = 'status'
export const STOCK_QUANTITY = 'quantity'
export const STOCK_RESERVED = 'quantityReserved'

export const STOCK_SKU_HEADER = 'SKU'
export const STOCK_NAME_HEADER = 'Nombre'
export const STOCK_STATUS_HEADER = 'Estado'
export const STOCK_QUANTITY_HEADER = 'Existencias'
export const STOCK_RESERVED_HEADER = 'Comprometido'
export const STOCK_IMAGE_HEADER = 'Imagen'
