import React from 'react'
import { STORE_ICONS } from '../../../../constants/icons'
import HalfDonutPieChart from '../../../Dashboard/Charts/HalfDonutPieChart'

const StoreSLA = ({ value, timePeriod }) => (
  <div className="w-1/4 bg-white rounded-sm border border-gray-200 p-6 flex flex-col justify-between items-center">
    <div className="flex items-start justify-between mb-8">
      <div className="flex items-center">
      <img src={STORE_ICONS.sla} alt="servicio" className="h-5 w-5 mr-2" />
      <span className="text-gray-700">Nivel de servicio</span>
      </div>
    </div>
    <div className="flex justify-center mb-2">
      <HalfDonutPieChart
        data={[
          {
            name: '',
            value: parseInt(value * 100, 10),
            percentage: `${(value * 100).toFixed(2)}%`,
            fill: '#00cc66'
          }
        ]}
      title=""
    />
    </div>
    <div className="text-center text-gray-500 capitalize">{timePeriod}</div>
  </div>
)

export default StoreSLA
