import { React, useEffect, useState, useCallback, useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { ArrayParam, useQueryParam } from 'use-query-params'
import secureLocalStorage from 'react-secure-storage'

import useFetch from '../../hooks/useFetch'
import ConfirmModal from '../../components/shared/ConfirmModal/ConfirmModal'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import { MANIFEST_URL, SHIPPINGS_URL } from '../../constants/urls'
import { DELIVERY_TYPES, PACKAGE_TYPES } from '../../constants/sales'
import HeaderTitle from '../../components/shared/HeaderTitle'
import ManifestDetailsTable from '../../components/Manifest/ManifestDetailsTable'
import {boxIcon} from '../../constants/icons'
import RawLoader from '../../components/shared/Loader/RawLoader'
import SearchBar from '../../components/shared/SearchBar'
import { searchTermsGenerator } from '../../utils/filters'
import { isSubstring } from '../../utils/strings'
import { getFormattedDate } from '../../utils/dates'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import { MANIFEST_DETAIL_TABS } from '../../constants/manifest'
import ManifestsFilters from '../../components/Manifest/ManifestFilters'
import ROUTES from '../../constants/routes'
import { downloadFileUrl } from '../../utils/files'
import { closeManifest, createManifest } from '../../helpers/request/manifests'

const ManifestDetails = () => {
  const { manifestId } = useParams()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const courier = query.get('courier')
  const total = query.get('total')
  const firstStatus = query.get('firstStatus')
  const packageType = query.get('packageType')

  const autocompleteManifest = secureLocalStorage.getItem('autocompleteManifest')

  const [isLoadingManifestDetail, setIsLoadingManifestDetail] = useState(true)

  const [searchResult, setSearchResult] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [selectedStatus = [], setSelectedStatus] = useQueryParam('status', ArrayParam)
  const [isFiltered, setIsFiltered] = useState(false)
  const [filteredManifestItems, setFilteredManifestItems] = useState([])

  const [manifest, setManifest] = useState([])
  const [shippingsWithoutManifest, setShippingsWithoutManifest] = useState([])

  const [loadingClose, setLoadingClose] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [successOpen, setSuccessOpen] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [removedShippings, setRemovedShippings] = useState([])
  const [addedShippings, setAddedShippings] = useState([])
  const [selectedShippingCount, setSelectedShippingCount] = useState('')

  const [closeManifestDynamicId, setCloseManifestDynamicId] = useState('')

  const navigate = useNavigate()

  const searchBarFilter = (manifestsList, searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const filteredManifests = manifestsList.filter(
        (manifestItem) =>searchTerms.some((term) => (
          isSubstring(manifestItem.package.city, term) ||
          isSubstring(manifestItem.package.orderId, term) ||
          isSubstring(manifestItem.package.companyName, term) ||
          isSubstring(DELIVERY_TYPES[manifestItem.package.deliveryType], term) ||
          isSubstring(manifestItem.package.orderId, term) ||
          isSubstring(manifestItem.package.packageStatus, term) ||
          isSubstring(manifestItem.package.purchaseDate, term) ||
          isSubstring(manifestItem.package.state, term) ||
          isSubstring(manifestItem.trackingNumber, term)
        ))
      )
      setSearchResult(filteredManifests)
    } else {
      setSearchResult(manifestsList)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const shippingsList = shippingsWithoutManifest.shippings || manifest.shippings

    const manifestsList = isFiltered ? filteredManifestItems : shippingsList
    searchBarFilter(manifestsList, searchTerm)
  }

  const sortShippings = (shippingsList) => {
    const shippings = shippingsList
    shippings.sort(
      (shippingA, shippingB) =>
        shippingA.package &&
        shippingB.package &&
        Date.parse(shippingB.package.purchaseDate) - Date.parse(shippingA.package.purchaseDate)
    )
    return shippings
  }

  const setManifestData = useCallback((data) => {
    const shippings = sortShippings(data.shippings)
    setManifest({ ...data, shippings })
    setSearchResult(data.shippings)
  }, [])

  const params = useMemo(
    () => ({ params: { packageType: PACKAGE_TYPES[packageType], means: courier } }),
    [packageType, courier]
  )

  const setShippingsInfo = useCallback((data) => {
    setShippingsWithoutManifest(data)
    setSearchResult(data.shippings)
  }, [])


  const url = (firstStatus === 'create' && !autocompleteManifest) ? `${SHIPPINGS_URL}/show` : MANIFEST_URL(manifestId)
  const setter = (firstStatus === 'create' && !autocompleteManifest) ? setShippingsInfo : setManifestData

  const { isLoading, error } =
  useFetch(url, setter, params)

  const handleFilterChange = (manifestsList) => {
    setFilteredManifestItems(manifestsList)
    searchBarFilter(manifestsList, searchBarInput)
  }

  const handleStatusChange = (manifestsList) => {
    setFilteredManifestItems(manifestsList)
    searchBarFilter(manifestsList, searchBarInput)
  }

  const handleGoBack = () => {
    navigate(
      `${ROUTES.MANIFEST}`)
  }

  const updateManifestsList = async () => {
    setSearchBarInput('')
    handleGoBack()
  }

  const closeManifestSubmit = async () => {
    setLoadingClose(true)
    setSubmitError(false)
    try {
      const response = await closeManifest(manifestId, removedShippings)
      if (!response.manifestPdf) setSubmitError(true)
      else {
        setCloseManifestDynamicId(manifest.dynamicId)
        downloadFileUrl(response.manifestPdf)
      }
    } catch {
      setSubmitError(true)
    } finally {
      setConfirmOpen(false)
      setLoadingClose(false)
      setSuccessOpen(true)
    }
  }

  const createManifestSubmit = async () => {
    setLoadingClose(true)
    setSubmitError(false)
    try {
      const response =
        await createManifest(courier, PACKAGE_TYPES[packageType], false, addedShippings)
      if (!response.manifestPdf) setSubmitError(true)
      else {
        setCloseManifestDynamicId(response.dynamicId)
        downloadFileUrl(response.manifestPdf)
      }
    } catch {
      setSubmitError(true)
    } finally {
      setConfirmOpen(false)
      setLoadingClose(false)
      setSuccessOpen(true)
    }
  }

  const statusManifests =
    selectedStatus.length === 0
      ? searchResult
      : searchResult
      .filter((packageManifest) => selectedStatus.includes(packageManifest.package.serviceType))

  const currentManifests = statusManifests

  useEffect(() => {
    if (manifestId.shippings){
      let statusFiltered = manifest.shippings
      statusFiltered = statusFiltered.filter(shipping =>
        selectedStatus.includes(shipping.package.deliveryType))
      handleStatusChange(statusFiltered, searchBarInput)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus, manifest])

  useEffect(() => {
    setManifest([])
    setLoadingClose(false)
    setConfirmOpen(false)
    setRemovedShippings([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifestId])

  useEffect(() => {
    if (manifest.dynamicId && autocompleteManifest ){
      setIsLoadingManifestDetail(false)
    } else if (!autocompleteManifest && (shippingsWithoutManifest.shippings?.length > 0 || firstStatus === 'record')){
      setIsLoadingManifestDetail(false)
    }
  }, [autocompleteManifest, firstStatus, manifest, shippingsWithoutManifest])

  if (error) {
    return (
      <div className='bg-white border-x'>
        <p className="error">Ha ocurrido un error. Inténtelo más tarde.</p>
      </div>
    )
  }

  return (
    <div className="relative w-full min-h-screen bg-light-grey !overflow-hidden">
      <HeaderTitle
        title="Manifiesto"
        subtitle={courier}
        goBack={handleGoBack}
      />

      {isLoadingManifestDetail ? (
        <div className="loader-styles">
          <RawLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-col mx-10 mt-8 flex justify-between bg-white border rounded py-2 px-6">
            <div className='text-lg capitalize'>
              {firstStatus === 'create' ?
              courier : `Manifiesto: ${ manifest.dynamicId }`}
            </div>
            <div className="flex flex-row gap-2 bg-[#EAECF0] rounded-full w-fit px-2 py-1 text-xs mt-4">
              <img src={boxIcon} className="" alt="box" />
              {total || '-'} {firstStatus === 'create' ?
              'Pedidos' : 'Paquetes' }
            </div>
            <div className={`flex flex-row gap-4 mt-4 text-xs text-[#667085] ${ firstStatus === 'create' ? 'hidden' : ''}`}>
              <div>
                <div>Fecha de cierre</div>
                <div>{getFormattedDate(manifest.closedAt)}</div>
              </div>
              <div>
                <div>Courier</div>
                <div>{courier}</div>
              </div>
            </div>
          </div>

          <div className={`${ firstStatus === 'create' ? '' : 'hidden'} ${ manifest.packageType === 0 ? '' : 'hidden'}`}>
            <ActionsBar
              statusFilters={MANIFEST_DETAIL_TABS}
              selectedFilters={selectedStatus}
              setSelectedFilters={setSelectedStatus}
              classNameRightChildren='ml-2'
            />
          </div>
          <div className={`flex flex-row gap-2 mx-10 bg-white border-x px-4 pb-2
           ${ manifest.packageType === 0 ? '' : 'pt-2'}`}>
            <div className="w-52">
              <SearchBar
                searchbarInput={searchBarInput}
                searchKeyword={searchHandler}
                setSearchbarInput={(searchInput) => {
                  setSearchBarInput(searchInput)
                }}
              />
            </div>
            <div className={`${ firstStatus === 'create' ? '' : 'hidden'}`}>
              <ManifestsFilters
                isFiltered={isFiltered}
                setIsFiltered={setIsFiltered}
                handleFilterChange={handleFilterChange}
                shippings={(!autocompleteManifest && firstStatus !== 'record') ?
                  shippingsWithoutManifest.shippings: manifest.shippings}
                marketplace={manifest.packageType === PACKAGE_TYPES[1]}
              />
            </div>
          </div>
          <div className=''>
            <div className="">
              <ManifestDetailsTable
                autocompleteManifest={autocompleteManifest}
                manifest={autocompleteManifest ? manifest : shippingsWithoutManifest}
                shippings={currentManifests}
                isLoading={isLoading}
                setRemovedShippings={setRemovedShippings}
                setAddedShippings={setAddedShippings}
                courier={courier}
                dynamicId={manifest.dynamicId}
                setConfirmOpen={setConfirmOpen}
                setSelectedShippingCount= {setSelectedShippingCount}
                className='flex flex-row justify-center'
                record={firstStatus==='record'}
              />
            </div>
            <ResponseModal
              isModalOpen={submitError}
              handleClose={() => setSubmitError(false)}
              error
            />
          </div>
        </>
      )}
      <ConfirmModal
        isModalOpen={confirmOpen}
        loading={loadingClose}
        handleClose={() => setConfirmOpen(false)}
        handleConfirm={autocompleteManifest? closeManifestSubmit : createManifestSubmit}
        twoButtons
      >
        <div className='flex flex-col justify-center items-center w-[200px] text-[#5E6570]'>
          <div className='font-medium text-base mb-4'>Crear Manifiesto</div>
          <div className='text-center'>
            <span className='font-normal text-sm'>¿Estas seguro/a de que deseas crear este manifiesto con </span>
            <span className='font-bold text-sm'>{selectedShippingCount} Pedidos</span>
          </div>
        </div>
      </ConfirmModal>
      <ResponseModal
        handleClose={() => {
            updateManifestsList()
            setSuccessOpen(false)
          }
        }
        isModalOpen={successOpen}
        error={submitError}
        successMessage={`Manifiesto ${closeManifestDynamicId} cerrado con exitó`}
      />
    </div>
  )
}

export default ManifestDetails
