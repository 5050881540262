import React from 'react'

import { TableRow } from '../../../shared/Table/Table'
import {
  DELIVERY_TYPES_TRANSLATION,
  SALE_DATE_HEADER,
  SALE_DELIVERY_TYPE_HEADER,
  SALE_SERVICE_TYPE_HEADER,
  SALE_STATUS_HEADER
} from '../../../../constants/sales'
import {
  PACKAGE_STATUS_COLORS,
  PACKAGE_STATUS_TRANSLATE_DISTINCT
} from '../../../../constants/packageStatus'
import { PACKAGE_STATUS_ICONS } from '../../../../constants/icons'
import { getFormattedDateWithoutTimezone } from '../../../../utils/dates'
import { SERVICES_TYPES_TRANSLATE } from '../../../../constants/couriers'

const OrderRow = ({ order, headers, handleSelectOrder }) => (
  <TableRow
    items={headers.map((header) => {
      const value = order[header.value]

      if (header.name === SALE_DATE_HEADER) {
        return getFormattedDateWithoutTimezone(value)
      }

      if (header.name === SALE_STATUS_HEADER) {
        const { backgroundColor, textColor } = PACKAGE_STATUS_COLORS[value]

        return (
          <div
            className="inline-flex rounded-full py-1 text-xs text-center items-center justify-center px-3"
            style={{
              backgroundColor,
              color: textColor,
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            <img src={PACKAGE_STATUS_ICONS[value]} alt="status" className="h-4 w-4 mr-1" />
            {PACKAGE_STATUS_TRANSLATE_DISTINCT[value]}
          </div>
        )
      }

      if (header.name === SALE_DELIVERY_TYPE_HEADER) {
        return DELIVERY_TYPES_TRANSLATION[value]
      }

      if (header.name === SALE_SERVICE_TYPE_HEADER) {
        return SERVICES_TYPES_TRANSLATE[value]
      }

      return value
    })}
    onClick={() => handleSelectOrder(order)}
  />
)

export default OrderRow
