import React, { useState, useContext, useEffect } from 'react'

import Button from '../../shared/Button'
import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'

const PrismPosConfigurationForm = ({ onSubmit }) => {
  const {
    personalization,
    setPersonalization,
    companyId,
    step,
    setStep,
    setConfigurationSubmitted,
    posName,
    prismServerName,
    prismUsername,
    prismPassword,
    criticStock,
    securityStock,
    setCriticStock,
    setSecurityStock,
    setPrismServerName,
    setPrismUsername,
    setPrismPassword
  } = useContext(StockConfigurationContext)

  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [originalValues, setOriginalValues] = useState({
    prismServerName: '',
    prismUsername: '',
    prismPassword: '',
    criticStock: '',
    securityStock: ''
  })

  useEffect(() => {
    if (isEditing) {
      setOriginalValues({
        prismServerName: prismServerName || '',
        prismUsername: prismUsername || '',
        prismPassword: prismPassword || '',
        criticStock: criticStock || '',
        securityStock: securityStock || ''
      })
    }
  }, [isEditing, prismServerName, prismUsername, prismPassword, criticStock, securityStock])

  const handleModify = () => {
    setIsEditing(true)
  }

  const handleCancel = () => {
    setPrismServerName(originalValues.prismServerName)
    setPrismUsername(originalValues.prismUsername)
    setPrismPassword(originalValues.prismPassword)
    setCriticStock(originalValues.criticStock)
    setSecurityStock(originalValues.securityStock)

    setIsEditing(false)
  }

  const handleContinue = () => {
    setStep(step + 1)
  }
  // eslint-disable-next-line
  const handleSave = async () => {
    setIsSubmitting(true)

    try {
      const body = {
        stockFlow: { ...personalization.stockFlow, active: true, destination: 'cms' },
        prismCredentials: { ...personalization.prismCredentials }
      }

      if (posName) body.stockFlow.origin = posName
      if (prismServerName) body.prismCredentials.server_name = prismServerName
      if (prismUsername) body.prismCredentials.user = prismUsername
      if (prismPassword) body.prismCredentials.password = prismPassword
      if (criticStock) body.stockFlow.critic_stock = criticStock
      if (securityStock) body.stockFlow.security_stock = securityStock

      await onSubmit(companyId, body)

      setPersonalization({
        ...personalization,
        stockFlow: body.stockFlow,
        prismCredentials: body.prismCredentials
      })

      setConfigurationSubmitted(true)
      setIsEditing(false)
    } catch (error) {
      console.error('Error submitting form:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="flex flex-col mt-4 space-y-4 items-start">
      <div> Nombre del servidor Prism</div>
      <input
        className={`border rounded py-2 px-4 mt-2 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setPrismServerName(e.target.value)}
        value={prismServerName || ''}
        placeholder="Nombre del servidor Prism"
        disabled={!isEditing}
      />

      <div>Nombre de usuario Prism</div>
      <input
        className={`border rounded py-2 px-4 mt-2 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setPrismUsername(e.target.value)}
        value={prismUsername || ''}
        placeholder="Nombre de usuario Prism"
        disabled={!isEditing}
      />

      <div>Contraseña Prism</div>
      <input
        className={`border rounded py-2 px-4 mt-2 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setPrismPassword(e.target.value)}
        value={prismPassword || ''}
        placeholder="Contraseña Prism"
        disabled={!isEditing}
      />

      <div>Stock Crítico</div>
      <input
        className={`border rounded py-2 px-4 mt-2 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setCriticStock(e.target.value)}
        value={criticStock || ''}
        placeholder="Stock Crítico"
        disabled={!isEditing}
      />

      <div>Stock de Seguridad</div>
      <input
        className={`border rounded py-2 px-4 mt-2 mb-4 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setSecurityStock(e.target.value)}
        value={securityStock || ''}
        placeholder="Stock de Seguridad"
        disabled={!isEditing}
      />

      <div className="flex justify-between w-full space-x-8">
        {isEditing ? (
          <>
            <Button
              color="bg-gray-400 w-full h-14"
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button
              color="bg-normal-pinflag w-full h-14"
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Guardando...' : 'Guardar'}
            </Button>
          </>
        ) : (
          <>
            <Button
              color="bg-normal-pinflag w-full h-14"
              onClick={handleModify}
            >
              Modificar
            </Button>
            {(prismUsername || prismPassword || prismServerName || criticStock || securityStock)
              && (
              <Button
                color="bg-normal-pinflag w-full h-14"
                onClick={handleContinue}
              >
                Continuar
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default PrismPosConfigurationForm
