import React, { useState, useEffect } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { COLORS } from '../../../../constants/styles'
import { WAREHOUSE_URL } from '../../../../constants/urls'
import randomApi from '../../../../axiosConfig/randomApi'
import { DEFAULT_STORE_SCHEDULE } from '../../../../constants/stores'
import {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  MONDAY_CODE,
  TUESDAY_CODE,
  WEDNESDAY_CODE,
  THURSDAY_CODE,
  FRIDAY_CODE,
  SATURDAY_CODE,
  SUNDAY_CODE
} from '../../../../constants/days'
import { findSchedule } from '../../../../helpers/warehouse'
import { ticketIcon, STOCKS_ICONS, STORE_ICONS } from '../../../../constants/icons'
import StoreSearchButton from '../../../../pages/Settings/Stocks/StoreSearchButton'
import { getCmsLocations } from '../../../../helpers/request/stocks'
import useFetch from '../../../../hooks/useFetchParams'
import EditBox from '../../../Settings/Personalization/EditBox2'
import usePostLocationMatches from '../../../Settings/Stock/usePostLocationMatches'
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch.style'
import RawLoader from '../../../shared/Loader/RawLoader'
import LoadingError from '../../../shared/LoadingError'
import Button from '../../../shared/Button'

import StoreRackQRGenerator from './StoreRackQRGenerator'
import OrderScheduleRowEdit from './ScheduleRowEdit'

const StorePickup = ({ store, onUpdate }) => {
  const warehouseId = store.id

  const [maxAisles, setMaxAisles] = useState(store.PickupPointWarehouse.aisle)
  const [maxColumns, setMaxColumns] = useState(store.PickupPointWarehouse.column)
  const [maxHeight, setMaxHeight] = useState(store.PickupPointWarehouse.height)
  const [capacity, setCapacity] = useState(store.PickupPointWarehouse.capacity)
  const [activeStorePickup, setActiveStorePickup] = useState(store.PickupPointWarehouse.active)
  const [activePickFromStore, setActivePickFromStore] =
    useState(store.PickupPointWarehouse.activePickFromStore)
  const warehouseLocation = store.Location

  const mondaySchedule = findSchedule(store.schedules, MONDAY)
  const tuesdaySchedule = findSchedule(store.schedules, TUESDAY)
  const wednesdaySchedule = findSchedule(store.schedules, WEDNESDAY)
  const thursdaySchedule = findSchedule(store.schedules, THURSDAY)
  const fridaySchedule = findSchedule(store.schedules, FRIDAY)
  const saturdaySchedule = findSchedule(store.schedules, SATURDAY)
  const sundaySchedule = findSchedule(store.schedules, SUNDAY)

  const [companyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const defaultSchedule = DEFAULT_STORE_SCHEDULE
  const [cmsStores, setCmsStores] = useState([])

  const [monday, setMonday] = useState(mondaySchedule || defaultSchedule)
  const [tuesday, setTuesday] = useState(tuesdaySchedule || defaultSchedule)
  const [wednesday, setWednesday] = useState(wednesdaySchedule || defaultSchedule)
  const [thursday, setThursday] = useState(thursdaySchedule || defaultSchedule)
  const [friday, setFriday] = useState(fridaySchedule || defaultSchedule)
  const [saturday, setSaturday] = useState(saturdaySchedule || defaultSchedule)
  const [sunday, setSunday] = useState(sundaySchedule || defaultSchedule)

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [error, setError] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isCmsDropdownOpen, setIsCmsDropdownOpen] = useState(false)
  const [selectedCmsStore, setSelectedCmsStore] = useState(null)
  const [isEditingLocationMatch, setIsEditingLocationMatch] = useState(false)

  const orderedSchedules = [
    { day: MONDAY_CODE, schedule: mondaySchedule, setState: setMonday, state: monday },
    { day: TUESDAY_CODE, schedule: tuesdaySchedule, setState: setTuesday, state: tuesday },
    { day: WEDNESDAY_CODE, schedule: wednesdaySchedule, setState: setWednesday, state: wednesday },
    { day: THURSDAY_CODE, schedule: thursdaySchedule, setState: setThursday, state: thursday },
    { day: FRIDAY_CODE, schedule: fridaySchedule, setState: setFriday, state: friday },
    { day: SATURDAY_CODE, schedule: saturdaySchedule, setState: setSaturday, state: saturday },
    { day: SUNDAY_CODE, schedule: sundaySchedule, setState: setSunday, state: sunday }
  ]

  const {
    isLoading: isCmsLoading,
    error: cmsError
  } = useFetch(getCmsLocations, setCmsStores, companyId)

  const {
    onPostLocationMatches,
    loading: isPostLocationMatchesLoading,
    error: postLocationMatchesError,
    success: postLocationMatchesSuccess
  } = usePostLocationMatches()

  // eslint-disable-next-line
  const handleSubmit = async () => {
    const pickupBody = {
      name: store.name,
      pickupPoint: {
        aisle: maxAisles,
        column: maxColumns,
        height: maxHeight,
        capacity,
        active: activeStorePickup,
        activePickFromStore
      }
    }

    const schedulesBody = {
      schedules: [monday, tuesday, wednesday, thursday, friday, saturday, sunday],
      type: 'pickupPoint'
    }

    const matchCreationData = {
      matchData: [{
        warehouseId,
        cmsId: selectedCmsStore?.id,
        storeId: store.StoreWarehouse?.storeId
      }]
    }

    setIsLoadingSubmit(true)

    try {
      await randomApi().put(`${WAREHOUSE_URL(warehouseId)}/schedules`, schedulesBody)
      await randomApi().put(WAREHOUSE_URL(warehouseId), pickupBody)
      if (selectedCmsStore) {
        await onPostLocationMatches(companyId, matchCreationData)
      }
      setIsUpdated(false)

      await onUpdate()
    } catch {
      setError(true)
    } finally {
      setIsLoadingSubmit(false)
      setSubmitted(true)
      setIsEditingLocationMatch(false)
    }
  }

  const handleCancel = () => {
    setMaxAisles(store.PickupPointWarehouse.aisle)
    setMaxColumns(store.PickupPointWarehouse.column)
    setMaxHeight(store.PickupPointWarehouse.height)
    setCapacity(store.PickupPointWarehouse.capacity)
    setActiveStorePickup(store.PickupPointWarehouse.active)
    setActivePickFromStore(store.PickupPointWarehouse.activePickFromStore)
    orderedSchedules.forEach(({ schedule, setState }) => {
      setState(schedule)
    })

    setIsUpdated(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setSubmitted(false)
    }, 3000)
  }, [submitted])

  const handleSelectCmsStore = (cmsStore) => {
    setSelectedCmsStore(cmsStore)
    setIsCmsDropdownOpen(false)
    setIsUpdated(true)
  }

  const handleCancelLocationMatch = () => {
    setIsEditingLocationMatch(false)
    setIsUpdated(true)
  }

  return (
    <>
      <div className="flex flex-col space-y-8 mt-8">
        <EditBox
          title="Retiro en Tienda"
          width="w-full"
        >
          <div className="flex justify-between border border-gray-200 rounded-md p-4">
            <div className="text-sm text-gray-500">Habilita o deshabilita la tienda para ser usada como punto de entrega</div>
            <ToggleSwitch
              size="small"
              inactiveColor={COLORS.LIGHT_GREY}
              check={activeStorePickup}
              setCheck={() => {
                setActiveStorePickup(!activeStorePickup)
                setIsUpdated(true)
            }}
          />
          </div>
        </EditBox>

        <EditBox
          title="Retiro en Tienda con Stock de Tienda"
          width="w-full"
        >
          <div className="flex flex-col space-y-4 border border-gray-200 rounded-md p-4">
            <div className="flex justify-between">
              <div className="text-sm text-gray-500">Habilita o deshabilita el stock de tu tienda
                para hacer entregas desde la tienda</div>
              <ToggleSwitch
                size="small"
                inactiveColor={COLORS.LIGHT_GREY}
                check={activePickFromStore}
                setCheck={() => {
                  setActivePickFromStore(!activePickFromStore)
                  setIsUpdated(true)
              }}
              />
            </div>
            {(store.StoreWarehouse?.cmsId && activePickFromStore && !isEditingLocationMatch) && (
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <img src={ticketIcon} alt="Ticket" className="h-5 w-5 mr-2" />
                  <div className="text-sm text-green-500">Ya existe una integración con esta tienda</div>
                  <div className="flex justify-between w-full mx-8">
                    {isEditingLocationMatch ?
                     (
                      <Button
                        color="bg-normal-pinflag"
                        onClick={() => handleCancelLocationMatch()}
                        small
                      >
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        color="bg-normal-pinflag"
                        onClick={() => {
                          setIsEditingLocationMatch(true)
                          setIsUpdated(true)
                        }}
                        small
                      >
                        Editar
                      </Button>
                    )}
                  </div>
                </div>
                <div className="flex items-center w-full h-12">
                  <div className="flex-shrink-0 w-20 flex items-center justify-center pr-5 relative overflow-visible">
                    <img src={STOCKS_ICONS.shopify} alt="Logo" className="h-5 w-16"/>
                  </div>

                  <div className="flex bg-white text-dark-grey shadow-lg rounded-lg w-full border border-gray-200 h-full text-xs font-normal">
                    <div className="flex-1 flex items-center justify-center">{store?.name}</div>
                    <div className="flex-1 flex items-center justify-center border-l border-gray-300">{warehouseLocation?.comuna.nombrecomuna}</div>
                    <div className="flex-1 flex items-center justify-center border-l border-gray-300">{warehouseLocation?.address}</div>
                  </div>
                </div>
              </div>
            )}
            {isCmsLoading && <RawLoader />}
            {cmsError && <LoadingError />}
            {(!store.StoreWarehouse || (!store.StoreWarehouse.cmsId && activePickFromStore) ||
            isEditingLocationMatch) && (
              <div className="flex flex-col space-y-4">
                <div className="text-sm text-blue-500">Verifica el nombre de tu tienda</div>
                <div className="px-16">
                  <div className={`transition-all duration-300 ${isCmsDropdownOpen ? 'mb-40' : 'mb-0'}`}>
                    <StoreSearchButton
                      stores={cmsStores}
                      selectedStore={selectedCmsStore}
                      handleSelectStore={handleSelectCmsStore}
                      placeholder='Selecciona la tienda CMS'
                      isDropdownOpen={isCmsDropdownOpen}
                      toggleDropdown={() => setIsCmsDropdownOpen(!isCmsDropdownOpen)}
                      logoSrc={STOCKS_ICONS.shopify}
                    />
                  </div>
                </div>
              </div>
            )}

          </div>
        </EditBox>

        <EditBox
          title="Horario"
          width="w-full"
        >
          <div className="flex flex-col space-y-4">
            <div className="text-sm text-gray-500">Configura el horario en el que operará el servicio. Recuerda dejar un margen antes de abrir y cerrar la tienda</div>
            <div className="border border-gray-200 rounded-md p-4 w-1/3">
              <div className="flex items-center mb-4 justify-between">
                <img src={STORE_ICONS.schedule} alt="Schedule" className="mr-2" />
              </div>
              {orderedSchedules.map(({ day, setState, state }) => (
                <OrderScheduleRowEdit
                  key={day}
                  day={day}
                  dayState={state}
                  setDayState={setState}
                  setIsUpdated={setIsUpdated}
                />
              ))}
            </div>
          </div>
        </EditBox>

        <EditBox
          title="Almacenamiento"
          description="Administra la capacidad de tu almacenamiento para optimizar la organización y gestión de tu espacio"
          width="w-full"
        >
        <div className="flex space-x-8">
        <EditBox
            title="Capacidad"
          >
            <div className="flex flex-col">
              <div className="flex flex-col mb-4">
                <div className="text text-ultra-dark-grey">Pasillos</div>
                <input
                  type="number"
                  value={maxAisles}
                  onChange={(e) => {
                    setMaxAisles(e.target.value)
                    setIsUpdated(true)
                  }}
                  className="flex border border-gray-300 rounded-md w-12 text-sm text-dark-grey justify-center items-center px-2"
                />
              </div>
              <div className="flex flex-col mb-4">
                <div className="text text-ultra-dark-grey">Columnas</div>
                <input
                  type="number"
                  value={maxColumns}
                  onChange={(e) => {
                    setMaxColumns(e.target.value)
                    setIsUpdated(true)
                  }}
                  className="flex border border-gray-300 rounded-md w-12 text-sm text-dark-grey justify-center items-center px-2"
                />
              </div>
              <div className="flex flex-col mb-4">
                <div className="text text-ultra-dark-grey">Pisos</div>
                <input
                  type="number"
                  value={maxHeight}
                  onChange={(e) => {
                    setMaxHeight(e.target.value)
                    setIsUpdated(true)
                  }}
                  className="flex border border-gray-300 rounded-md w-12 text-sm text-dark-grey justify-center items-center px-2"
                />
              </div>
              <div className="flex flex-col mb-4">
                <div className="text text-ultra-dark-grey">Capacidad máxima</div>
                <input
                  type="number"
                  value={capacity}
                  onChange={(e) => {
                    setCapacity(e.target.value)
                    setIsUpdated(true)
                  }}
                  className="flex border border-gray-300 rounded-md w-20 text-sm text-dark-grey justify-center items-center px-2"
                />
              </div>
            </div>
          </EditBox>
          <EditBox
            title="Código QR Rack"
          >
            <StoreRackQRGenerator
              maxAisle={maxAisles}
              maxDepth={maxColumns}
              maxHeight={maxHeight}
            />
          </EditBox>
          </div>
        </EditBox>
      </div>
      {(error || postLocationMatchesError) && <LoadingError />}
      {isUpdated && !isLoadingSubmit && (
        <div className="flex gap-2 justify-center mt-2">
          <Button color="bg-normal-pinflag" onClick={handleSubmit} small>
            Guardar
          </Button>
          <Button color="bg-dark-grey" onClick={handleCancel} small>
            Cancelar
          </Button>
        </div>
      )}
      {(isLoadingSubmit || isPostLocationMatchesLoading) && <RawLoader />}
      {(submitted && postLocationMatchesSuccess) && (
        <div className="flex justify-center mt-2">
          <div className="text-normal-pinflag">¡Guardado exitoso!</div>
        </div>
      )}
    </>
  )
}

export default StorePickup
