import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react'

import { fetchActualSales, getPreviousDataSales, getNextDataSales,
  setNextSaleFunction, setPreviousSaleFunction,
  setNewParamsFunction, setNullNeighbordsValues
 } from '../helpers/sales'

export const SalesContext = createContext()

const SalesProvider = ({ children }) => {
  const [actualFilteredSales, setActualFilteredSales] = useState(null)
  const [actualSale, setActualSale] = useState(null)
  const [previousSale, setPreviousSale] = useState(null)
  const [nextSale, setNextSale] = useState(null)
  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem('params')
    return storedParams ? JSON.parse(storedParams) : null
  })
  const [actualPage, setActualPage] = useState(null)
  const [previousFilteredSales, setPreviousFilteredSales] = useState(null)
  const [nextFilteredSales, setNextFilteredSales] = useState(null)
  const [allPreviousRoute, setAllPreviousRoute] = useState(() => {
    const storedRoute = localStorage.getItem('route')
    return storedRoute || null
  })
  const [viewMode, setViewMode] = useState('default')

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const initializeData = async () => {
      if (!actualFilteredSales) {
        fetchActualSales(signal, setActualPage, setActualFilteredSales)
      }
    }

    initializeData()

    return () => {
      controller.abort()
    }
  }, [actualFilteredSales, actualPage, params])


  useEffect(() => {
    if (params) {
      localStorage.setItem('params', JSON.stringify(params))
    }
  }, [params])

  useEffect(() => {
    if (allPreviousRoute) {
      localStorage.setItem('route', allPreviousRoute)
    }
  }, [allPreviousRoute])

  const movePreviousSales = useCallback(async (index, previousDataSales) => {
    if (!previousFilteredSales || !params?.page || params.page <= 1) return

    setPreviousSaleFunction(index, null, setPreviousSale, params,
      previousDataSales)
    setNextSaleFunction(index, params, actualFilteredSales, setNextSale, null)


    setNextFilteredSales(actualFilteredSales)
    setActualFilteredSales(previousFilteredSales)
    const isPrevious = true
    setNewParamsFunction(setParams, params, isPrevious)
  }, [actualFilteredSales, params, previousFilteredSales])

  const moveNextSales = useCallback(async (index, nextDataSales) => {
    if (!nextFilteredSales) return
    setPreviousSaleFunction(index, actualFilteredSales, setPreviousSale, params,
      null)
    setNextSaleFunction(index, params, null, setNextSale, nextDataSales)

    setPreviousFilteredSales(actualFilteredSales)
    setActualFilteredSales(nextFilteredSales)
    const isPrevious = false
    setNewParamsFunction(setParams, params, isPrevious)
  }, [actualFilteredSales, nextFilteredSales, params])

  const setPreviousNeighbors = (previousDataSales, index) => {
    setNextFilteredSales(null)
    setPreviousFilteredSales(previousDataSales)
    setPreviousSaleFunction(index, previousDataSales, setPreviousSale, params,
      actualFilteredSales)
    setNextSaleFunction(index, params, null, setNextSale, actualFilteredSales)
  }

  const setNextNeighbors = (nextDataSales, index) => {
    setNextFilteredSales(nextDataSales)
    setPreviousFilteredSales(null)
    setPreviousSaleFunction(index, null, setPreviousSale, params,
      actualFilteredSales)
    setNextSaleFunction(index, params, nextDataSales, setNextSale, actualFilteredSales)
  }

  // eslint-disable-next-line react-func/max-lines-per-function
  useEffect(() => {
    // eslint-disable-next-line react-func/max-lines-per-function
    const handleSalesNavigation = async () => {
      if (viewMode === 'store') return
      setNullNeighbordsValues(setPreviousSale, setNextSale)
      if (!actualSale || !actualFilteredSales) {
        return
      }

      let index = actualFilteredSales.findIndex(sale => sale.packageId === actualSale.packageId)
      if (index !== -1 ){
        if (index === 0) {
          const previousDataSales = await getPreviousDataSales(params)
          setPreviousNeighbors(previousDataSales, index)
        }
        else if (index === actualFilteredSales.length - 1) {
          const nextDataSales = await getNextDataSales(params)
          setNextNeighbors(nextDataSales, index)
        } else {
          setPreviousSaleFunction(index, null, setPreviousSale, params,
            actualFilteredSales)
          setNextSaleFunction(index, params, actualFilteredSales, setNextSale, actualFilteredSales)
        }
      } else {
        if (previousFilteredSales) {
          index = previousFilteredSales.findIndex(sale => sale.packageId === actualSale.packageId)
        }
        if (index === -1 && nextFilteredSales) {
          index = nextFilteredSales.findIndex(sale => sale.packageId === actualSale.packageId)
          await moveNextSales(index, nextFilteredSales)
        } else {
          await movePreviousSales(index, previousFilteredSales)
        }
      }
    }

    handleSalesNavigation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSale, viewMode])

  const value = useMemo(
    () => ({
      actualFilteredSales,
      setActualFilteredSales,
      actualSale,
      setActualSale,
      previousSale,
      setPreviousSale,
      nextSale,
      setNextSale,
      setParams,
      allPreviousRoute,
      setAllPreviousRoute,
      viewMode,
      setViewMode
    }),
    [actualFilteredSales, actualSale, previousSale, nextSale, allPreviousRoute, viewMode]
  )

  return (
    <SalesContext.Provider value={value}>
      {children}
    </SalesContext.Provider>
  )
}

export default SalesProvider
