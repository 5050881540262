import { React, useContext } from 'react'

import { ticketSaleIcon } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'
import { STATUS_COLOR, TICKETS_REASONS } from '../../../constants/tickets'
import { notificationContext } from '../../../contexts/NotificationContext'
import { getFormattedDate } from '../../../utils/dates'

const TicketInfo = ({ ticket }) => {
  const { ticketsMessages } = useContext(notificationContext)

  const getTicketTypeName = (statusValue) => {
    const found = TICKETS_REASONS.find(reason => reason.value === statusValue)
    return found ? found.name : statusValue
  }

  const unreadMessages = ticketsMessages[ticket.id] || 0

  return (
  <div className="border bg-white p-6 rounded-t">
    <div className="flex mb-2 gap-5 items-center">
      <a
        href={`${ROUTES.TICKETS}/${ticket.id}`}
        className='flex gap-3 items-center cursor-pointer hover:bg-gray-200 rounded-lg p-1'
      >
        <img src={ticketSaleIcon} alt="Ticket" className="w-6" />
        Solicitud #{ticket.id}
      </a>
      <div className={`flex rounded w-max py-1 px-2 text-sm ${STATUS_COLOR[ticket.status]}`}>
        {ticket.status}
      </div>
      <div className='text-xs text-dark-grey'>
        {getFormattedDate(ticket.createdAt)}
      </div>
      {unreadMessages > 0 && (
        <div className='bg-red rounded-xl text-white px-2 text-sm p-1 font-light'>
          {unreadMessages} {unreadMessages === 1 ? 'actualización' : 'actualizaciones'} sin revisar
        </div>
      )}
    </div>
    <div className='flex flex-row gap-16 text-sm'>
      <div className="mt-4 flex flex-col">
        <div className="font-medium">Motivo</div>
        <div className="mt-1 text-medium-dark-grey">
          {getTicketTypeName(ticket.type)}
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="font-medium">Descripción</div>
        <div className="mt-1 text-medium-dark-grey">
          {ticket.description}
        </div>
      </div>
    </div>
  </div>
)
}

export default TicketInfo
