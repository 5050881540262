import React, { useState } from 'react'

import { UPDATE_PACKAGE_URL, UPDATE_PACKAGE_WITH_ERROR_URL } from '../../../../constants/urls'
import { downloadFileUrl } from '../../../../utils/files'
import randomApi from '../../../../axiosConfig/randomApi'
import Modal from '../../../shared/Modal/Modal'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'

import ChangeAddressForm from './ChangeAddressForm'

const ModifyPackage = ({
  isOpen,
  handleClose,
  packageId,
  saleData,
  clickAndCollect,
  stateId,
  city,
  warehouseName,
  packageStatus,
  states,
  packageWithError = false
}) => {
  const [editing, setEditing] = useState({ personal: false, shipping: false })

  const [isLoading, setIsLoading] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const makeBody = (data) => {
    let body = { ...data }
    if (!editing.personal) delete body.customer
    if (!editing.shipping) body = { customer: data.customer }
    return { package: body }
  }

  const handleConditionalSubmit = async (data) => {
    if (!editing.personal && !editing.shipping) return

    setIsLoading(true)
    const body = makeBody(data)

    try {
      const response = (!packageWithError && saleData.cityId)
        ? await randomApi().put(UPDATE_PACKAGE_URL(packageId), body)
        : await randomApi().put(UPDATE_PACKAGE_WITH_ERROR_URL(packageId), body)

      if (response.data?.all_labels_url) {
        downloadFileUrl(response.data.all_labels_url)
      }
      setSubmitError(false)
    } catch {
      setSubmitError(true)
    } finally {
      setIsLoading(false)
      setOpenResponseModal(true)
    }
  }

  return (
    <Modal show={isOpen} handleClose={handleClose}>
      <div className="m-2 text-ultra-dark-grey text-lg pb-2">Editar información de entrega</div>
      <ChangeAddressForm
        saleData={saleData}
        packageId={packageId}
        packageWithError={packageWithError}
        onSubmitForm={handleConditionalSubmit}
        isLoading={isLoading}
        clickAndCollect={clickAndCollect}
        stateId={stateId}
        city={city}
        warehouseName={warehouseName}
        packageStatus={packageStatus}
        editing={editing}
        setEditing={setEditing}
        states={states}
      />
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={
          submitError ? () => setOpenResponseModal(false) : () => window.location.reload()
        }
        successMessage="Pedido modificado exitosamente."
        error={submitError}
      />
    </Modal>
  )
}

export default ModifyPackage
