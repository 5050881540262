import React from 'react'
import { STORE_ICONS } from '../../../../constants/icons'
import { SHIP_FROM_STORE } from '../../../../constants/stores'

import StoreCapacityChart from './StoreCapacityChart'

const StoreCapacity = ({ store, serviceType }) => {
  const isShipFromStore = serviceType === SHIP_FROM_STORE

  const packageCount = isShipFromStore
    ? store.ShippingWarehouse.packageCount
    : store.PickupPointWarehouse.packagesCount

  const capacity = isShipFromStore
    ? store.ShippingWarehouse.capacity
    : store.PickupPointWarehouse.capacity

    return (
      <div className="w-1/5 flex flex-col bg-white rounded-sm border border-gray-200 p-4 h-auto justify-start items-center">
        <div className="flex text-md text-ultra-dark-grey mb-4 items-center">
          <img src={STORE_ICONS.capacity} alt="store-logo" className="h-5 w-5 mr-2" />
        <div>Capacidad</div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <StoreCapacityChart
          data={[
            { name: 'Ocupado', value: packageCount, fill: '#00FF00' },
            { name: 'Disponible', value: capacity - packageCount, fill: '#E0E0E0' }
          ]}
          total={capacity}
        />
        <div className="flex flex-col justify-center items-center">
            <div className="text-xl text-ultra-dark-grey mb-4">
              {packageCount} / {capacity}
            </div>
        </div>
      </div>
    </div>
  )
}

export default StoreCapacity
