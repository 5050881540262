import React, { useContext, useEffect, useState } from 'react'

import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import Button from '../../../components/shared/Button'
import { POS_OPTIONS } from '../../../constants/stocks'
import useSubmitPersonalization from '../../../components/Settings/useSubmitPersonalization'
import useFetch from '../../../hooks/useFetchParams'
import { getPersonalization } from '../../../helpers/request/personalization'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'

const PosSelection = () => {
  const {
    step,
    setStep,
    posName,
    setPosName,
    posNameSubmitted,
    setPosNameSubmitted,
    companyId,
    personalization,
    setPersonalization,
    setAccessToken,
    setCriticStock,
    setSecurityStock,
    setPrismServerName,
    setPrismUsername,
    setPrismPassword
  } = useContext(StockConfigurationContext)

  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [originalPosName, setOriginalPosName] = useState('')

  const { onSubmit } = useSubmitPersonalization()
  const {
    isLoading: isPersonalizationLoading,
    error: personalizationError
  } = useFetch(getPersonalization, setPersonalization, companyId)

  useEffect(() => {
    if (personalization.stockFlow) {
      if (personalization.stockFlow.origin) {
        setPosName(personalization.stockFlow.origin)
      }
      if (personalization.stockFlow.pos_token) setAccessToken(personalization.stockFlow.pos_token)
      if (personalization.stockFlow.critic_stock) {
        setCriticStock(personalization.stockFlow.critic_stock)
      }
      if (personalization.stockFlow.security_stock) {
        setSecurityStock(personalization.stockFlow.security_stock)
      }
    }

    if (personalization.prismCredentials) {
      setPrismServerName(personalization.prismCredentials.server_name)
      setPrismUsername(personalization.prismCredentials.user)
      setPrismPassword(personalization.prismCredentials.password)
    }

    if (posNameSubmitted) {
      setIsEditing(false)
    }
  }, [
    setPosName,
    posNameSubmitted,
    personalization,
    setPersonalization,
    setAccessToken,
    setCriticStock,
    setSecurityStock,
    setPrismServerName,
    setPrismUsername,
    setPrismPassword
  ])

  const handleModify = () => {
    setOriginalPosName(posName)
    setIsEditing(true)
  }

  const handleCancel = () => {
    setPosName(originalPosName)
    setIsEditing(false)
  }

  const handleContinue = () => {
    setStep(step + 1)
  }

  const handleSave = async () => {
    setIsSubmitting(true)

    try {
      const body = { stockFlow: { ...personalization.stockFlow } }

      if (posName) {
        body.stockFlow.origin = posName
      }

      await onSubmit(companyId, body)
      setPersonalization({ ...personalization, stockFlow: body.stockFlow })
      setPosNameSubmitted(true)
      setIsEditing(false)
    } catch (error) {
      console.error('Error submitting form:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="flex flex-col mt-4 text-xs w-8/12">
      {isPersonalizationLoading && <RawLoader />}
      {personalizationError && <LoadingError />}
      {!isPersonalizationLoading && !personalizationError && (
        <>
          Pos
          <select
            className={`border rounded py-2 px-4 mt-2 mb-6 text-sm w-full ${isEditing ? 'text-black' : 'text-gray-500'}`}
            onChange={(e) => setPosName(e.target.value)}
            value={posName}
            disabled={!isEditing}
          >
            {
              POS_OPTIONS.map((pos) => (
                <option key={pos.value} value={pos.value}>
                  {pos.label}
                </option>
              ))
            }
          </select>
          <br />

          <div className="flex justify-between w-full space-x-4">
            {isEditing ? (
              <>
                <Button
                  color="bg-gray-400 w-full h-12"
                  onClick={handleCancel}
                  width="w-full"
                  className="text-black"
                  disabled={isSubmitting}
                >
                  Cancelar
                </Button>
                <Button
                  color="bg-normal-pinflag w-full h-12"
                  onClick={handleSave}
                  width="w-full"
                  className="text-white"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Guardando...' : 'Guardar'}
                </Button>
              </>
            ) : (
              <div className="flex justify-between w-full space-x-4">
                <Button
                  color="bg-normal-pinflag w-full h-12"
                  onClick={handleModify}
                  width="w-full"
                  className="text-white"
                >
                  Modificar
                </Button>
                <Button
                  color="bg-normal-pinflag w-full h-12"
                  onClick={handleContinue}
                  width="w-full"
                  className="text-white"
                >
                  Continuar
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default PosSelection
